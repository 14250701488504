import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from './api/axios';
import AuthContext from "./context/AuthProvider";
import CareerGeniusLogo from './assets/CareerGeniusLogo.png';
import FolderIcon from './assets/FolderIcon.png';
import './UploadResume.css';
import ProgressBar from "./components/ProgressBar";

import FeaturedEmployers from "./FeaturedEmployers";
import SocialProof from "./SocialProof";


const STEP_LABELS = [
  "Upload Resume",
  "Job Details",
  "Critique",
  "Optimize / Finalize"
];

const UploadResume = () => {
  const { auth } = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');
  const [alreadyUploaded, setAlreadyUploaded] = useState(false);
  const [resumeDetails, setResumeDetails] = useState(null);
  const navigate = useNavigate();


  useEffect(() => { 
    axios.post('/log-activity', {  sessionId: auth.sessionId,event_type:"Page Landing",current_page:'UploadResume',object_type:'Page',specific_object:'Landed on UploadResume page'}, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  }, []);

  // On mount, fetch resume details for the current user
  useEffect(() => {
    if (!auth.username) return; // Not logged in
    axios.post('/get-resume-details', { username: auth.username })
      .then((response) => {
        if (response.data.resume) {
          setAlreadyUploaded(true);
          setResumeDetails(response.data.resume);
        }
      })
      .catch((error) => {
        console.error('Error fetching resume details:', error);
      });
  }, [auth.username]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setMessage('');
  };

  const handleUpload = async () => {
    if (!auth.username) {
      //console.log("User not logged in. Redirecting to login...");
      navigate("/LoginForm");
      return;
    }

    if (!selectedFile) {
      setMessage('Please select a file to upload.');
      console.log("No file selected.");
      return;
    }

    setUploading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('resume', selectedFile);
    formData.append('username', auth.username);

    try {
      console.log("Uploading file...", selectedFile.name);
      const response = await axios.post('/upload-resume', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log("Upload response:", response.data);
      setMessage('Resume uploaded successfully!');
      window.location.replace("/JobDetails");
    } catch (error) {
      console.error("Upload failed:", error);
      setMessage('Upload failed. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.post('/delete-resume', { username: auth.username });
      setAlreadyUploaded(false);
      setResumeDetails(null);
      setMessage("Resume deleted successfully.");
    } catch (error) {
      console.error("Error deleting resume:", error);
      setMessage("Failed to delete resume.");
    }
  };

  return (
    <div className="home-container">
      <header className="header">
        <img src={CareerGeniusLogo} alt="CareerGenius Logo" className="logo" />
      </header>

      <div className="form-container">
        <h2>Upload <span className="highlight">Resume</span></h2>
        {alreadyUploaded ? (
            <>
                {/* Row for the filename & Delete button */}
                <div className="resume-info-row">
                <p className="resume-filename">{resumeDetails.original_filename}</p>
                <button className="btn delete-btn" onClick={handleDelete}>
                    <span className="delete-icon">✖</span>
                </button>
                </div>
                {/* Note about replacing */}
                <p className="upload-note">
                You already have a resume on file. Uploading a new file will <strong>replace</strong> your existing resume.
                </p>
            </>
            ) : (
            <p>
                You only need to upload your resume once. We will tailor it for each job you apply for!
            </p>
            )}



        <div className="upload-container">
          <input
            type="file"
            id="fileUpload"
            className="file-input"
            onChange={handleFileChange}
            accept=".pdf, .docx"
          />
          <label htmlFor="fileUpload" className="upload-box">
            <img src={FolderIcon} alt="Upload Folder" className="upload-icon animated-hover" />
            <span className="upload-text">
              {alreadyUploaded ? "Click to Replace" : "Click to Upload"}
            </span>
          </label>
        </div>

        {selectedFile && (
          <p className="file-name">{selectedFile.name}</p>
        )}

        <button className="btn upload-btn" onClick={handleUpload} disabled={uploading}>
          {uploading ? 'Uploading...' : (alreadyUploaded ? 'Replace Resume' : 'Upload Resume')}
        </button>

        {message && <p className="upload-message">{message}</p>}
        <ProgressBar
        currentStep={1}
        totalSteps={4}
        stepLabels={[
          "Upload Resume",
          "Job Details",
          "Critique",
          "Optimize / Finalize"
        ]}
        stepPaths={[
          "/UploadResume",
          "/JobDetails",
          `/critique/${auth.applicationId}`,
          "/optimize"
        ]}
      />
      </div>

     
      <FeaturedEmployers />
      <SocialProof />
    </div>
  );
};

export default UploadResume;
