import React, { useState, useEffect,useContext, useRef,useCallback } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import axios from "./api/axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import ResumeParser from "./functions/ChatGPTResumeParser";
import "./DocumentBuilderPage.css";
import TEMPLATES from "./ResumeTemplates/templates.jsx";
import AIResumeFormatter from "./functions/AIResumeFormatter";
import { 
    detectAndHandleContentOverflow, 
    setupContentOverflowDetection,
    enhancePDFExportWithPageHandling,
    usePageOverflowDetection
  } from './functions/ContentOverflowDetector';
import AuthContext from "./context/AuthProvider";
import ProgressBar from "./components/ProgressBar.jsx";
// Additional components
import FeaturedEmployers from "./FeaturedEmployers";
import SocialProof from "./SocialProof";
import StripeSubscriptionButton from './components/StripeSubscriptionButton.jsx';
// Modal for Sales Pitch
import Modal from "./components/ResumeCritique/SalesPitchPage";


const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_API_KEY;

const stripe = require('stripe')(STRIPE_SECRET_KEY);



/**
 * Main component for the document builder
 */
export default function DocumentBuilderEditor() {
  const { applicationId } = useParams();
  const editorContainerRef = useRef(null);
  const grapesEditorRef = useRef(null);
  const previewRef = useRef(null);
  const tempContainerRef = useRef(null);
  const { auth , setAuth } = useContext(AuthContext);

  // State
  const [originalResume, setOriginalResume] = useState("");
  const [parsedResumeData, setParsedResumeData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [finalHtml, setFinalHtml] = useState("");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showParseModal, setShowParseModal] = useState(false);
  const [parseProgress, setParseProgress] = useState(0);
  const [showTemplates, setShowTemplates] = useState(false);
  
// Add these state definitions to your component
const [jobRequirements, setJobRequirements] = useState("");
const [jobTitle, setJobTitle] = useState("");
const [companyName, setCompanyName] = useState("");
const [optimizedResume, setOptimizedResume] = useState("");
const [optimizing, setOptimizing] = useState(false);
const [optimizationProgress, setOptimizationProgress] = useState(0);
const [optimizedScore, setOptimizedScore] = useState(0);
const [averageScore, setAverageScore] = useState(0); // Default value
const [scoreImprovement, setScoreImprovement] = useState(0); // Default value

const [payRange, setPayRange] = useState("");


// Add these state variables near your other state declarations
const [textScale, setTextScale] = useState(1.0); // Default scale
const [showScaleControls, setShowScaleControls] = useState(true);

const [autoFormatted, setAutoFormatted] = useState(false);

// Add these state variables to store the original state
const [initialHtml, setInitialHtml] = useState("");
const [initialResumeData, setInitialResumeData] = useState(null);


// Add state for sales pitch modal
const [showSalesPitchModal, setShowSalesPitchModal] = useState(false);
const [missingElements, setMissingElements] = useState("Key skills and achievements");
const [hasSubscription, setHasSubscription] = useState(false);

//optimizedResumeText
const [globalOptimizedResumeText, setGlobalOptimizedResumeText] = useState("");

// Add a state to track whether we should show attention effect
const [showButtonAttention, setShowButtonAttention] = useState(false);



// Add this constant at the top of your file, outside the component
const OPENAI_API_KEY = process.env.REACT_APP_GPT_API_KEY;
const [subscriptionCanceled, setSubscriptionCanceled] = useState(false);
const location = useLocation(); // If using React Router

useEffect(() => {
  // Parse query parameters from URL
  const queryParams = new URLSearchParams(location.search);
  const subscriptionStatus = queryParams.get('subscription');
  
  if (subscriptionStatus === 'canceled') {
    // Handle subscription cancellation
    setSubscriptionCanceled(true);
    
    // Get sessionId from localStorage
    const sessionId = localStorage.getItem('sessionId');
    
    if (sessionId) {
      // Make API call to update user role
      axios.post('/update-user-role-after-cancel', {
        sessionId: sessionId
      })
      .then(response => {
        if (response.data.success) {
          console.log('User role updated successfully');
          
          // Update auth context and localStorage with new status
          setAuth(prev => ({ ...prev, user_role_type: 1, user_role_type_name: 'Member' }));
          localStorage.setItem('user_role_type', 1);
          localStorage.setItem('user_role_type_name', 'Member');
        }
      })
      .catch(error => {
        console.error('Failed to update user role:', error);
      });
    }
    
    alert("Your subscription has been canceled.");
  } 
  else if (subscriptionStatus === 'success') {
    // Handle successful subscription purchase
    const sessionId = localStorage.getItem('sessionId');
    
    if (sessionId) {
      // Refresh user data to get updated role
      axios.post('/get-user-subscription', {
        sessionId: sessionId
      })
      .then(response => {
        if (response.data) {
          // Update auth context with new premium status
          setAuth(prev => ({ 
            ...prev, 
            user_role_type: 4, 
            user_role_type_name: 'BASIC PLAN' 
          }));
          
          // Update localStorage to reflect premium status
          localStorage.setItem('user_role_type', 4);
          localStorage.setItem('user_role_type_name', 'BASIC PLAN' );
          
          console.log('User data updated after successful subscription');
        }
      })
      .catch(error => {
        console.error('Failed to refresh user data:', error);
      });
    }
    
    // Show confirmation message
    alert("Congratulations! You are now a Premium member with access to all features.");
  }
  
  // Remove query parameters from URL to prevent showing alerts on refresh
  if (subscriptionStatus) {
    const newUrl = window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);
  }
}, [location, setAuth ]);




useEffect(() => { 
  axios.post('/log-activity', {  sessionId: auth.sessionId,event_type:"Page Landing",current_page:'DocumentBuilderPage' ,object_type:'Page',specific_object:'Landed on DocumentBuilderPage page' }, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  });
}, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const newIsMobileView = window.innerWidth < 768;
      setIsMobileView(newIsMobileView);
      
      // Reinitialize editor when switching between mobile and desktop
      if (newIsMobileView !== isMobileView && finalHtml) {
        setTimeout(() => {
          initializeEditor(finalHtml);
        }, 100);
      }
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileView, finalHtml]);

  // Effect to load saved scores on mount (or when applicationId/auth.sessionId changes)
  
    async function loadScores() {
      const result = await loadApplicationScores();
      // Do nothing if scores exist
      if (result && result.optimized_resume_score) {
        setOptimizedScore(parseInt(result.optimized_resume_score, 10));
        setAverageScore(parseInt(result.original_resume_score, 10));
      }
    }
    
  
  

  useEffect(() => {
    async function calculateScores() {
      try {
        
        const baseScore = averageScore; // Use the saved original score
        let optScore;
        try {
          if (globalOptimizedResumeText && jobRequirements && OPENAI_API_KEY) {
            const critiques = await fetchCritiqueFromOpenAI(jobRequirements, globalOptimizedResumeText);
            if (critiques && critiques.length > 0) {
              const sum = critiques.reduce((acc, factor) => acc + factor.score, 0);
              optScore = Math.round(sum / critiques.length);
            }
          }
        } catch (aiError) {
          console.error("Error scoring with OpenAI:", aiError);
        }
        if (!optScore) {
          const baseImprovement = Math.floor(Math.random() * 8) + 40;
          optScore = Math.min(97, baseScore + baseImprovement);
        }
        // Only update state if not already set from saved data
        if (optimizedScore <= 0) {
          setOptimizedScore(optScore);
          updateResumeScores(optScore);
          setScoreImprovement(optScore - baseScore);
        }
      } catch (error) {
        console.error("Error calculating scores:", error);
        const baseScore = typeof averageScore === 'number' ? averageScore : 0;
        setOptimizedScore(Math.min(97, baseScore + 44));
        setScoreImprovement(optimizedScore - averageScore);
      } finally {
     
      }
    }
    
    // Load saved scores, then if none, calculate new scores.
    loadApplicationScores().then(saved => {
      if (!saved || optimizedScore <= 0) {
        calculateScores();
      }
    });
  
  }, [averageScore, jobRequirements, globalOptimizedResumeText, OPENAI_API_KEY]);


      // Effect to periodically add attention class to download button
    useEffect(() => {
      // Start attention effect after a delay when component mounts
      const initialDelay = setTimeout(() => {
        setShowButtonAttention(true);
        
        // Set up interval to toggle attention class
        const attentionInterval = setInterval(() => {
          setShowButtonAttention(prev => !prev);
        }, 2000); // Toggle every 3 seconds
        
        return () => clearInterval(attentionInterval);
      }, 1000); // Initial delay of 2 seconds
      
      return () => clearTimeout(initialDelay);
    }, []);

  // Add an effect to auto-trigger formatting once the resume data and template are available
useEffect(() => {
    if (parsedResumeData && selectedTemplate && !autoFormatted) {
      // Automatically format the resume using your existing function
      handleFormatWithAI();
      setAutoFormatted(true);
    }
  }, [parsedResumeData, selectedTemplate, autoFormatted]);


// Enhanced initialization effect that fetches, optimizes and applies to template
useEffect(() => {
  const maxAttempts = 5;
  let attemptCount = 0;
    async function initializeResumeBuilder() {
      try {
        
        setError("");
        loadScores();
        

        
        // 1. Fetch resume and job data
        //console.log("Fetching resume for application ID:", applicationId);
        const response = await axios.get(`/critique/${applicationId}?sessionId=${auth.sessionId}`);
        
        if (!response.data.success) {
          console.error("API request unsuccessful:", response.data);
          setError("No data returned from the backend.");
          return;
        }
        
        const { 
          original_resume_text, 
          job_requirements,
          job_title,
          company_name
        } = response.data;
        
        if (!original_resume_text) {
          console.warn("No resume text in response");
          setError("No resume content found in the response.");
          return;
        }
        
        //console.log("Resume text received, length:", original_resume_text.length);
        
        // 2. Store fetched data
        setOriginalResume(original_resume_text);
        setJobRequirements(job_requirements);
        setJobTitle(job_title || "this position");
        setCompanyName(company_name || "this company");
        
        // 3. Optimize resume with OpenAI
        setOptimizing(true);
        let resumeTextToUse = original_resume_text;
        
        try {
          // Simulate progress while optimizing
          const progressInterval = setInterval(() => {
            setOptimizationProgress(prev => Math.min(prev + 5, 95));
          }, 300);
          
          const optimizedText = await optimizeResumeWithAI(original_resume_text, job_requirements);
          clearInterval(progressInterval);
          setOptimizationProgress(100);
          
          if (optimizedText && optimizedText.length > 100) {
            resumeTextToUse = optimizedText;
            setOptimizedResume(optimizedText);
            //console.log("Resume successfully optimized, length:", optimizedText.length);
            //console.log("Sample:", optimizedText.substring(0, 100));
            
            // Short delay to show 100% completion
            await new Promise(resolve => setTimeout(resolve, 800));
          } else {
            console.warn("Optimization returned invalid result, using original resume");
            setOptimizedResume(original_resume_text);
          }
        } catch (optimizeError) {
        //  console.error("Error in optimization:", optimizeError);
          ////console.log("Using original resume as fallback due to optimization error");
        } finally {
          setOptimizing(false);
        }
        
        // 4. Parse the resume text
        if (TEMPLATES && TEMPLATES.length > 0) {
          //console.log("Starting resume parsing with text length:", resumeTextToUse.length);
          
          try {
            const parser = new ResumeParser(resumeTextToUse);
            const parsedData = await parser.parse();
            
            if (!parsedData) {
              throw new Error("Parser returned null or undefined data");
            }
            
            /* console.log("Resume successfully parsed:", {
              name: parsedData.name || "[not found]",
              sections: {
                experience: (parsedData.experience || []).length,
                education: (parsedData.education || []).length,
                skills: (parsedData.skills || []).length
              }
            }); */
            
            // Set the parsed data
            setParsedResumeData(parsedData);
            
            // 5. Get first template and apply parsed data
            const firstTemplate = TEMPLATES[0];
            setSelectedTemplate(firstTemplate);
            
            const templateHtml = firstTemplate.getHTML();
            //console.log("Template HTML obtained, length:", templateHtml.length);
            
            // Fill template with parsed data
            const filledHtml = fillTemplateWithData(templateHtml, parsedData);
            //console.log("Filled template HTML generated, length:", filledHtml.length);

            // After successfully parsing the resume and filling the template:
            // Store the initial filled HTML and parsed data
            setInitialHtml(filledHtml);
            setInitialResumeData(parsedData);
            
            // Update the HTML state
            setFinalHtml(filledHtml);
            
            // 6. Initialize editor with filled template after a short delay
            setTimeout(() => {
              //console.log("Initializing editor with filled HTML");
              initializeEditor(filledHtml);
              
              // Apply font size adjustments after editor initialization
         /*      setTimeout(() => {
                adjustTemplateFontSizes();
              }, 500); */
            }, 150);
          } catch (parseError) {
            console.error("Error parsing resume:", parseError);
            setError(`Failed to parse resume: ${parseError.message}`);
          }
        } else {
          console.error("No templates available");
          setError("No resume templates found.");
        }
      } catch (err) {
        console.error("Error initializing resume builder:", err);
        setError("Failed to initialize resume builder. Please wait a few moments and refresh page to try again.");
      } finally {
        setLoading(false);
      }
    }

      // This function will try to initialize and, in case of failure, retry.
  async function tryInitialize() {
    try {
      await initializeResumeBuilder();
      // If successful, you might log or clear retry counters if needed.
    } catch (error) {
      attemptCount++;
      console.error(`Initialization attempt ${attemptCount} failed:`, error);
      if (attemptCount < maxAttempts) {
        // Delay before retrying (e.g. 500ms).
        setTimeout(tryInitialize, 2000);
      } else {
        setError("Failed to initialize resume builder after 5 attempts. Please refresh the page or try again later.");
      }
    }
  }
    
    if (applicationId) {
      setLoading(true);
          // Delay the initialization by 2000ms (adjust as needed)
    setTimeout(() => {
      tryInitialize();
    }, 2000);
    } else {
      console.warn("No application ID provided");
    }
  }, [applicationId]);



  // Add this function to your component
  async function loadApplicationScores() {
    try {
      const result = await fetchJobApplicationFields(
        applicationId, 
        auth.sessionId,
        ["original_resume_score", "optimized_resume_score","pay_range"]
      );
      
      if (result.success && result.data) {
        if (result.data.original_resume_score) {
          const originalScore = parseInt(result.data.original_resume_score, 10);
          console.log(originalScore);
          setAverageScore(originalScore);
        }
        if (result.data.pay_range) {
          const savedPayRange = parseInt(result.data.pay_range, 10);
          console.log(savedPayRange);
          setPayRange(savedPayRange);
        }

        if (result.data.optimized_resume_score) {
          const savedOptimizedScore = parseInt(result.data.optimized_resume_score, 10);
          console.log(savedOptimizedScore);
          setOptimizedScore(savedOptimizedScore);
        }
        console.log("Successfully loaded application scores from database");
        return true;
      } else {
        console.warn("Failed to load application scores:", result.message);
        return false;
      }
    } catch (err) {
      console.error("Error loading application scores:", err);
      return false;
    }
  }
  

  function scoreColor(score) {
    if (score >= 90) return "#00aa66"; // Dark green for excellent scores
    if (score >= 80) return "#00cc88"; // Green for good scores
    if (score >= 60) return "#ffbb33"; // Amber for average scores
    return "#ff4444"; // Red for low scores
  }


  async function fetchJobApplicationFields(applicationId, sessionId, fields = "all") {
    try {
      const response = await axios.get('/get-job-application-data', {
        params: { sessionId, applicationId, fields }
      })
      

      
      
      if (response.data.success) {
        return { 
          success: true,
          data: response.data.data 
        };
      } else {
        console.warn("Failed to fetch job application data:", response.data.message);
        return { 
          success: false, 
          message: response.data.message 
        };
      }
    } catch (err) {
      console.error("Error fetching job application data:", err);
      return { 
        success: false, 
        message: "Error connecting to server" 
      };
    }
  }



    // Function to update resume scores in the database
    async function updateResumeScores(optimizedScore) {
      try {
        // Call the flexible update endpoint with just the score fields
        const response = await axios.post('/update-job-application', {
          sessionId: auth.sessionId,
          applicationId: applicationId,
          optimized_resume_score: optimizedScore,

        });
        
        if (response.data.success) {
          console.log("Successfully updated resume scores in the database:", response.data);
          return true;
        } else {
          console.warn("Failed to update resume scores:", response.data.message);
          return false;
        }
      } catch (err) {
        console.error("Error updating resume scores:", err);
        return false;
      }
    }

  function createFixedToolbar(editor, isMobileView) {
    // Only proceed for mobile view
    if (!isMobileView) {
      //console.log("Mobile view not active, not creating custom toolbar");
      return;
    }
  
    //console.log("Creating mobile-only toolbar");
  
    // Remove any existing toolbar
    const existingToolbar = document.getElementById('mobile-editor-toolbar');
    if (existingToolbar) {
      existingToolbar.remove();
    }
  
    // Create simplified toolbar with Delete, Clone, Undo and Redo buttons
    const mobileToolbar = document.createElement('div');
    mobileToolbar.id = 'mobile-editor-toolbar';
    mobileToolbar.innerHTML = `
      <div class="toolbar-element-info">Selected Element</div>
      <div class="toolbar-actions">
        <button class="toolbar-btn undo-btn">Undo</button>
        <button class="toolbar-btn redo-btn">Redo</button>
        <button class="toolbar-btn delete-btn">Delete</button>
        <button class="toolbar-btn duplicate-btn">Clone</button>
      </div>
    `;
    
    // Add simple styling
    const style = document.createElement('style');
    style.textContent = `
      #mobile-editor-toolbar {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        background: white;
        border-radius: 8px;
        box-shadow: 0 3px 10px rgba(0,0,0,0.2);
        display: flex;
        padding: 10px 15px;
        z-index: 99999;
        align-items: center;
        justify-content: space-between;
        width: auto;
        min-width: 300px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
      }
      
      #mobile-editor-toolbar.active {
        opacity: 1;
        pointer-events: all;
      }
      
      .toolbar-element-info {
        font-size: 14px;
        color: #333;
        margin-right: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
      }
      
      .toolbar-actions {
        display: flex;
        gap: 8px;
      }
      
      .toolbar-btn {
        padding: 8px 12px;
        border-radius: 4px;
        border: none;
        background: #f5f5f5;
        color: #333;
        font-weight: 500;
        cursor: pointer;
      }
      
      .toolbar-btn.delete-btn {
        background: #f5f5f5;
        color: #d32f2f;
      }
      
      .toolbar-btn.duplicate-btn {
        background: #f5f5f5;
        color: #1976d2;
      }
      
      .toolbar-btn.undo-btn,
      .toolbar-btn.redo-btn {
        background: #f5f5f5;
        color: #333;
      }
      
      .toolbar-btn.undo-btn:active,
      .toolbar-btn.redo-btn:active,
      .toolbar-btn.delete-btn:active,
      .toolbar-btn.duplicate-btn:active {
        transform: scale(0.95);
      }
      
      /* Hide native toolbar on mobile */
      @media (max-width: 768px) {
        .gjs-toolbar {
          display: none !important;
        }
      }
    `;
    
    // Add to document
    document.head.appendChild(style);
    document.body.appendChild(mobileToolbar);
    
    // Track selected component
    let selectedComponent = null;
    
    // Show toolbar when component is selected
    editor.on('component:selected', component => {
      if (!component) return;
      
      // Store reference
      selectedComponent = component;
      
      // Show toolbar
      const toolbar = document.getElementById('mobile-editor-toolbar');
      toolbar.classList.add('active');
      
      // Update element info
      const infoElement = toolbar.querySelector('.toolbar-element-info');
      let elementName = component.get('tagName') || 'Element';
      if (component.get('type') === 'text') elementName = 'Text';
      infoElement.textContent = elementName;
    });
    
    // Hide toolbar when deselected
    editor.on('component:deselected', () => {
      selectedComponent = null;
      const toolbar = document.getElementById('mobile-editor-toolbar');
      toolbar.classList.remove('active');
    });
    
    // Set up button functionality
    const deleteBtn = mobileToolbar.querySelector('.delete-btn');
    const duplicateBtn = mobileToolbar.querySelector('.duplicate-btn');
    const undoBtn = mobileToolbar.querySelector('.undo-btn');
    const redoBtn = mobileToolbar.querySelector('.redo-btn');
    
    // Delete button
    deleteBtn.addEventListener('click', () => {
      if (selectedComponent) {
        // Simple remove
        selectedComponent.remove();
      }
    });
    
    // Duplicate button
    duplicateBtn.addEventListener('click', () => {
      if (selectedComponent) {
        try {
          // Clone component
          const collection = selectedComponent.collection;
          const index = collection.indexOf(selectedComponent);
          
          // Create and add clone
          const clone = selectedComponent.clone();
          collection.add(clone, { at: index + 1 });
          
          // Select the clone
          editor.select(clone);
        } catch (err) {
          console.error('Error cloning component:', err);
        }
      }
    });
    
    // Undo button
    undoBtn.addEventListener('click', () => {
      // Get the UndoManager
      const um = editor.UndoManager;
      if (um && um.hasUndo()) {
        um.undo();
        //console.log('Undo executed');
      } else {
        console.log('No actions to undo');
      }
    });
    
    // Redo button
    redoBtn.addEventListener('click', () => {
      // Get the UndoManager
      const um = editor.UndoManager;
      if (um && um.hasRedo()) {
        um.redo();
        //console.log('Redo executed');
      } else {
        console.log('No actions to redo');
      }
    });
  
    // Make toolbar always visible even without selection
    const toolbar = document.getElementById('mobile-editor-toolbar');
    toolbar.classList.add('active');
    
    // Update element info when no selection
    if (!selectedComponent) {
      const infoElement = toolbar.querySelector('.toolbar-element-info');
      infoElement.textContent = "Editor";
    }
  }

/**
 * Enables direct touch dragging for GrapeJS elements
 * This is added directly to initializeEditor to override GrapeJS's touch handling
 */

// Add this to your initializeEditor function
const initializeEditor = (html) => {
    if (!editorContainerRef.current) {
      console.error("Editor container reference is null");
      return;
    }
  
    // Log HTML content start for debugging
    //console.log(`Initializing editor with HTML (${html.length} chars): ${html.substring(0, 100)}...`);
  
    // Clean up existing editor
    if (grapesEditorRef.current) {
      //console.log("Destroying existing editor");
      grapesEditorRef.current.destroy();
      grapesEditorRef.current = null;
    }
  
    try {
      // Configure container
      if (editorContainerRef.current) {
        editorContainerRef.current.style.display = "block";
        editorContainerRef.current.style.position = "relative";
        editorContainerRef.current.style.minHeight = "800px";
        editorContainerRef.current.style.width = isMobileView ? "100%" : "calc(100% - 280px)";
      }
      
      // Initialize GrapeJS with simple configuration
      const editor = grapesjs.init({
        container: editorContainerRef.current,
        components: html,
        height: "800px",
        width: "100%",
        storageManager: { type: null },
        panels: { defaults: [] },
        blockManager: { blocks: [] },
        styleManager: { sectors: [] },
        noticeOnUnload: false,
        allowScripts: false
      });
    
      grapesEditorRef.current = editor;

       // Create mobile toolbar only for mobile view
       createFixedToolbar(editor, isMobileView);
      
      // Handle editor loaded event with simpler processing
      editor.on('load', () => {
        //console.log('Editor loaded');
        
         // Disable native toolbar for mobile
        if (isMobileView) {
          // Try to prevent native toolbar on mobile
          const frame = editor.Canvas.getFrameEl();
          if (frame) {
            const frameDoc = frame.contentDocument || frame.contentWindow.document;
            if (frameDoc) {
              const style = frameDoc.createElement('style');
              style.textContent = `
                .gjs-toolbar { 
                  display: none !important; 
                }
              `;
              frameDoc.head.appendChild(style);
            }
          }
        }


        // Make editable sections editable
        const components = editor.DomComponents.getComponents();
        processComponents(components);
        
        // Update HTML state
        updateHtmlAndPreview(editor.getHtml());
        
        // Set up content overflow detection after a short delay
        setTimeout(() => {
          setupContentOverflowDetection(grapesEditorRef);
        }, 800);
  
        // Call this function right after the editor is initialized
        setTimeout(() => {
          updatePageBreakIndicators(textScale);
        }, 1000);
        
        if(isMobileView){
          // ** IMPORTANT: Add direct touch drag handling **
          setupDirectTouchDragging(editor);
        }
        
      });
      
      // Simple component update handler
      editor.on('component:update', () => {
        updateHtmlAndPreview(editor.getHtml());
        
        // Check for content overflow when content is updated
        setTimeout(() => {
          detectAndHandleContentOverflow(grapesEditorRef);
        }, 300);
      });
    } catch (err) {
      console.error('Editor initialization error:', err);
      setError(`Failed to initialize editor: ${err.message}`);
    }
  };


// Add a function to handle resetting to the original state
const handleResetToOriginal = () => {
  try {
    setLoading(true);
    setError("");
    
    // Check if we have the initial state saved
    if (!initialHtml || !initialResumeData) {
      setError("Cannot reset - original state not available.");
      setLoading(false);
      return;
    }
    
    //console.log("Resetting resume to original state");
    
    // Reset the stored data
    setParsedResumeData(initialResumeData);
    setFinalHtml(initialHtml);
    
    // Reset the editor with the original HTML
    setTimeout(() => {
      initializeEditor(initialHtml);
      setLoading(false);
      setError("Resume successfully reset to original state.");
      
      // Auto-clear success message after 3 seconds
      setTimeout(() => {
        setError("");
      }, 3000);
    }, 100);
    
  } catch (err) {
    console.error("Error resetting resume:", err);
    setError(`Failed to reset resume: ${err.message}`);
    setLoading(false);
  }
};
  
/**
 * Setup direct touch dragging for mobile with improved button positioning
 * This bypasses GrapeJS dragging and implements a simple drag system
 */
function setupDirectTouchDragging(editor) {
    // Access the iframe document
    const frame = editor.Canvas.getFrameEl();
    if (!frame) return;
    
    const doc = frame.contentDocument || frame.contentWindow.document;
    if (!doc) return;
    
    // Add styles for mobile dragging
    const style = document.createElement('style');
    style.textContent = `
      .drag-handle {
        position: absolute;
        bottom: 10px;          /* Position at bottom instead of top */
        right: 10px;           /* Position at right instead of center */
        background: #4f46e5;
        color: white;
        padding: 12px 20px;    /* Larger touch target */
        border-radius: 8px;
        font-size: 16px;       /* Larger text */
        font-weight: bold;
        z-index: 9999;
        cursor: move;
        user-select: none;
        box-shadow: 0 3px 10px rgba(0,0,0,0.2);
      }
      
      .mobile-dragging {
        position: absolute !important;
        z-index: 9999 !important;
        opacity: 0.8 !important;
        box-shadow: 0 5px 20px rgba(0,0,0,0.3) !important;
        pointer-events: none !important;
      }
      
      .drag-placeholder {
        background: rgba(79, 70, 229, 0.1) !important;
        border: 2px dashed #4f46e5 !important;
        border-radius: 4px !important;
        min-height: 20px !important;
      }
      
      /* Add a visible outline to show selection better */
      .gjs-selected {
        outline: 2px solid #4f46e5 !important;
        outline-offset: 2px !important;
        position: relative !important;  /* Ensure position is set */
      }
      
      /* Add feedback when button is pressed */
      .drag-handle:active {
        background: #3730a3;
        transform: scale(0.95);
      }
    `;
    doc.head.appendChild(style);
    
    // Dragging state
    let isDragging = false;
    let currentElement = null;
    let placeholder = null;
    let startY = 0;
    let lastY = 0;
    let originalParent = null;
    let originalNext = null;
    
    // Create a drag handle for the selected component
    editor.on('component:selected', (component) => {
      // Wait a moment for the UI to stabilize
      setTimeout(() => {
        // Get the DOM element
        const el = component.view.el;
        if (!el) return;
        
        // Remove any existing drag handles
        const oldHandles = doc.querySelectorAll('.drag-handle');
        oldHandles.forEach(h => h.parentNode && h.parentNode.removeChild(h));
        
        // Create a new drag handle
        const handle = doc.createElement('div');
        handle.className = 'drag-handle';
        handle.textContent = 'Move';
        el.appendChild(handle);
        
        // Set up touch events on the handle
        handle.addEventListener('touchstart', (e) => {
          e.preventDefault();
          e.stopPropagation();
          
          // Start dragging when handle is touched
          startDrag(el, component, e);
        });
      }, 200);
    });
    
    // Remove handle when component is deselected
    editor.on('component:deselected', () => {
      const handles = doc.querySelectorAll('.drag-handle');
      handles.forEach(h => h.parentNode && h.parentNode.removeChild(h));
    });
    
    // Start dragging an element
    function startDrag(el, component, e) {
      // Get the touch position
      const touch = e.touches[0];
      startY = touch.clientY;
      lastY = startY;
      
      // Save element reference and position
      currentElement = el;
      originalParent = el.parentNode;
      originalNext = el.nextSibling;
      
      // Get element dimensions
      const rect = el.getBoundingClientRect();
      
      // Create placeholder
      placeholder = doc.createElement('div');
      placeholder.className = 'drag-placeholder';
      placeholder.style.height = `${rect.height}px`;
      
      // Insert placeholder
      el.parentNode.insertBefore(placeholder, el);
      
      // Style element for dragging
      el.classList.add('mobile-dragging');
      el.style.width = `${rect.width}px`;
      el.style.position = 'absolute';
      el.style.left = `${rect.left}px`;
      el.style.top = `${rect.top}px`;
      
      // Move to body for unrestricted movement
      doc.body.appendChild(el);
      
      // Set dragging state
      isDragging = true;
      
      // Set up move and end handlers
      doc.addEventListener('touchmove', handleMove);
      doc.addEventListener('touchend', handleEnd);
      
      // Provide haptic feedback if available
      if (navigator.vibrate) {
        navigator.vibrate(50);
      }
    }
    
    // Handle touchmove event
    function handleMove(e) {
      if (!isDragging || !currentElement) return;
      
      // Prevent scrolling
      e.preventDefault();
      
      // Get touch position
      const touch = e.touches[0];
      const moveY = touch.clientY - lastY;
      
      // Move the element
      const rect = currentElement.getBoundingClientRect();
      currentElement.style.top = `${rect.top + moveY}px`;
      
      // Update last position
      lastY = touch.clientY;
      
      // Find potential drop target
      currentElement.style.display = 'none';
      const elementAtPoint = doc.elementFromPoint(touch.clientX, touch.clientY);
      currentElement.style.display = '';
      
      if (!elementAtPoint) return;
      
      // Look for a component to drop onto
      let dropTarget = elementAtPoint;
      while (dropTarget && !dropTarget.hasAttribute('data-gjs-type') && dropTarget !== doc.body) {
        dropTarget = dropTarget.parentElement;
      }
      
      if (dropTarget && dropTarget.hasAttribute('data-gjs-type') && dropTarget !== currentElement) {
        // Determine position relative to drop target
        const dropRect = dropTarget.getBoundingClientRect();
        const insertBefore = touch.clientY < (dropRect.top + dropRect.height / 2);
        
        // Move placeholder
        if (insertBefore) {
          if (placeholder.nextSibling !== dropTarget) {
            dropTarget.parentNode.insertBefore(placeholder, dropTarget);
            
            // Haptic feedback
            if (navigator.vibrate) navigator.vibrate(20);
          }
        } else {
          if (dropTarget.nextSibling !== placeholder) {
            if (dropTarget.nextSibling) {
              dropTarget.parentNode.insertBefore(placeholder, dropTarget.nextSibling);
            } else {
              dropTarget.parentNode.appendChild(placeholder);
            }
            
            // Haptic feedback
            if (navigator.vibrate) navigator.vibrate(20);
          }
        }
      }
    }
    
    // Handle touchend event
    function handleEnd() {
      if (!isDragging || !currentElement) return;
      
      // Clean up dragging styles
      currentElement.classList.remove('mobile-dragging');
      currentElement.style.position = '';
      currentElement.style.width = '';
      currentElement.style.left = '';
      currentElement.style.top = '';
      
      // Get the final position from placeholder
      const finalParent = placeholder.parentNode;
      const finalNext = placeholder.nextSibling;
      
      // Remove placeholder
      if (placeholder.parentNode) {
        placeholder.parentNode.removeChild(placeholder);
      }
      
      // Move element to final position
      if (finalParent) {
        finalParent.insertBefore(currentElement, finalNext);
      } else {
        // Fallback to original position
        if (originalNext) {
          originalParent.insertBefore(currentElement, originalNext);
        } else {
          originalParent.appendChild(currentElement);
        }
      }
      
      // CRITICAL: Update GrapeJS model
      updateGrapeJSModel();
      
      // Haptic feedback
      if (navigator.vibrate) {
        navigator.vibrate([30, 50, 30]);
      }
      
      // Clean up
      doc.removeEventListener('touchmove', handleMove);
      doc.removeEventListener('touchend', handleEnd);
      
      // Reset state
      isDragging = false;
      currentElement = null;
      placeholder = null;
      originalParent = null;
      originalNext = null;
    }
    
    // Update GrapeJS model after DOM changes
    function updateGrapeJSModel() {
      //console.log('Updating GrapeJS model after drag...');
      
      try {
        // Get the HTML content of the document
        const html = doc.body.innerHTML;
        
        // Force GrapeJS to update its model from the current DOM
        editor.Components.clear();
        editor.setComponents(html);
        
        //console.log('Model updated successfully');
      } catch (err) {
        console.error('Error updating model:', err);
      }
    }
  }
  


  // Process components recursively to make them editable
  const processComponents = (components) => {
    if (!components) return;
    
    components.forEach(component => {
      // Get attributes
      const attrs = component.getAttributes();
      
      // Make data-gjs-editable components editable
      if (attrs && attrs['data-gjs-editable'] === 'true') {
        component.set('editable', true);
      }
      
      // Also make section components editable
      if (attrs && attrs['data-section']) {
        component.set('editable', true);
      }
      
      // Process children recursively
      const children = component.components();
      if (children && children.length) {
        processComponents(children);
      }
    });
  };

  // Update HTML and preview
  const updateHtmlAndPreview = (html) => {
    setFinalHtml(html);
    if (previewRef.current) {
      previewRef.current.innerHTML = html;
    }
  };



  const handleSelectTemplate = async (templateId) => {
    const template = TEMPLATES.find(t => t.id === templateId);
    if (!template) {
      console.error("Template not found:", templateId);
      return;
    }
    
    setSelectedTemplate(template);
    setLoading(true);
    
    try {
      // Get template HTML
      const html = template.getHTML();
      //console.log("Template HTML obtained, length:", html.length);
      
      // Ensure we have parsed data
      if (!parsedResumeData && originalResume) {
        //console.log("No parsed data available, parsing resume for template");
        const data = await parseResume();
        
        if (data) {
          // Log data structure for debugging
          //console.log("Resume parsed successfully");
          setParsedResumeData(data);
          
          // Fill template with data
          //console.log("Filling template with newly parsed data");
          const filledHtml = fillTemplateWithData(html, data);
          
          // Double-check filled HTML
          if (filledHtml.length <= html.length) {
            console.warn("Filled HTML is not longer than template, possible filling error");
          }
          
          setFinalHtml(filledHtml);
          
          // Initialize editor with filled content
          //console.log("Initializing editor with filled template");
          setTimeout(() => {
            initializeEditor(filledHtml);
            // Apply font size adjustments after initialization
       /*      setTimeout(() => {
              adjustTemplateFontSizes();
            }, 500); */
          }, 100);
        } else {
          // Fall back to empty template if parsing fails
          console.error("Resume parsing failed, using empty template");
          setFinalHtml(html);
          initializeEditor(html);
        }
      } 
      else if (parsedResumeData) {
        // We already have parsed data
        //console.log("Using existing parsed data");
        
        // Fill template with existing data
        //console.log("Filling template with existing parsed data");
        const filledHtml = fillTemplateWithData(html, parsedResumeData);
        
        // Double-check filled HTML
        if (filledHtml.length <= html.length) {
          console.warn("Filled HTML is not longer than template, possible filling error");
        }
        
        setFinalHtml(filledHtml);
        
        // Initialize editor with filled content
        //console.log("Re-initializing editor with filled template");
        setTimeout(() => {
          initializeEditor(filledHtml);
          // Apply font size adjustments after initialization
/*           setTimeout(() => {
            adjustTemplateFontSizes();
          }, 500); */
        }, 100);
      } else {
        // No resume data available
        console.warn("No resume data available, using empty template");
        setFinalHtml(html);
        initializeEditor(html);
      }
      
      // Mobile templates toggle
      if (isMobileView) {
        setShowTemplates(false);
      }
    } catch (err) {
      console.error("Error in template selection:", err);
      setError(`Failed to apply template: ${err.message}`);
      setFinalHtml(template.getHTML());
      initializeEditor(template.getHTML());
    } finally {
      setLoading(false);
    }
  };
  
  // Parse the resume with progress simulation
  const parseResume = async () => {
    if (!originalResume) {
      setError("No resume content available.");
      return null;
    }
  
    try {
      setParseProgress(0);
      //console.log("Original resume text length:", originalResume.length);
      //console.log("Resume text sample:", originalResume.substring(0, 200) + "...");
      
      // Simulate progress for UX
      const progressInterval = setInterval(() => {
        setParseProgress(prev => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return prev;
          }
          return prev + 10;
        });
      }, 100);
      
      // Create parser instance and parse the resume
      const parser = new ResumeParser(originalResume);
      const data = await parser.parse();
      
      // Complete progress
      clearInterval(progressInterval);
      setParseProgress(100);
      
      // Log parsed data for debugging
      /* console.log("Resume parsing results:", {
        name: data.name || "[not found]",
        email: data.contactInfo.email || "[not found]",
        phone: data.contactInfo.phone || "[not found]",
        summary: data.summary ? data.summary.substring(0, 50) + "..." : "[not found]",
        skills: data.skills.length > 0 ? `${data.skills.length} skills found` : "[not found]",
        experience: data.experience.length > 0 ? `${data.experience.length} entries found` : "[not found]",
        education: data.education.length > 0 ? `${data.education.length} entries found` : "[not found]",
        projects: data.projects.length > 0 ? `${data.projects.length} entries found` : "[not found]"
      });
       */
      // Return the parsed data
      return data;
    } catch (err) {
      console.error("Resume parsing error:", err);
      setError(`Error parsing resume: ${err.message}`);
      return null;
    }
  };

  async function enhanceExperienceEntries(resumeData) {
    if (!resumeData?.experience || !OPENAI_API_KEY) {
      console.warn("No experience data or API key for enhancing entries");
      return resumeData;
    }
    
    try {
      //console.log("Enhancing experience entries, count:", resumeData.experience.length);
      
      // Create a copy of the resume data
      const enhancedData = { ...resumeData };
      
      // Create formatter instance
      const formatter = new AIResumeFormatter(OPENAI_API_KEY);
      
      // Process each experience entry
      const enhancedExperience = await Promise.all(
        enhancedData.experience.map(async (exp, index) => {
          // Handle missing responsibilities by creating a placeholder
          if (!exp.responsibilities) {
            //console.log(`Entry ${index + 1}: Missing responsibilities, generating placeholder`);
            
            // Create a basic responsibility text from title and company
            const placeholderText = `Worked as ${exp.title || 'professional'} ${exp.company ? `at ${exp.company}` : ''}, handling various responsibilities and tasks related to the position.`;
            
            // Format this basic text
            try {
              const formattedPlaceholder = await formatter.formatResponsibilities(placeholderText);
              return { ...exp, responsibilities: formattedPlaceholder };
            } catch (err) {
              console.error(`Error formatting placeholder for entry ${index + 1}:`, err);
              return { ...exp, responsibilities: `• ${placeholderText}` };
            }
          }
          
          // Now we know responsibilities exists, proceed with formatting
          try {
            //console.log(`Formatting experience entry ${index + 1} for template`);
            
            // Execute formatting
            const formattedResp = await formatter.formatResponsibilities(exp.responsibilities);
            
            if (formattedResp && formattedResp.length > 0) {
              return { ...exp, responsibilities: formattedResp };
            }
          } catch (formatErr) {
            console.error(`Error formatting entry ${index + 1}:`, formatErr);
          }
          
          return exp;
        })
      );
      
      enhancedData.experience = enhancedExperience;
      return enhancedData;
    } catch (err) {
      console.error("Error enhancing experience entries:", err);
      return resumeData;
    }
  }
  

// 2. Add a debugging function to verify the resume data:
const logResumeData = (data) => {
    if (!data) {
      console.error("Resume data is null or undefined");
      return;
    }
    
    /* console.log("RESUME DATA:", {
      name: data.name || "[missing]",
      summary: data.summary ? data.summary.substring(0, 50) + "..." : "[missing]",
      contactInfo: {
        email: data.contactInfo?.email || "[missing]",
        phone: data.contactInfo?.phone || "[missing]"
      },
      experienceCount: data.experience?.length || 0,
      skillsCount: data.skills?.length || 0,
      educationCount: data.education?.length || 0
    }); */
  };

// 1. Update the fillTemplateWithData function for better formatting:


// Add this function to adjust the text scale
function adjustTextScale(newScale) {
  setTextScale(newScale);
  
  try {
    // Get the editor instance
    const editor = grapesEditorRef.current;
    if (!editor) {
      console.warn("Editor not initialized");
      return;
    }
    
    // Get the iframe document
    const iframe = editor.Canvas.getFrameEl();
    if (!iframe) {
      console.warn("Cannot find editor frame");
      return;
    }
    
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    if (!iframeDocument) {
      console.warn("Cannot access iframe document");
      return;
    }
    
    // Remove any existing scale styles
    const existingScaleStyle = iframeDocument.querySelector('style[data-text-scale]');
    if (existingScaleStyle) {
      existingScaleStyle.remove();
    }
    
    // Apply new scale styles
    const styleElement = iframeDocument.createElement('style');
    styleElement.setAttribute('data-text-scale', 'true');
    
    // Base font sizes that will be scaled
    const baseHeading1Size = 22;
    const baseHeading2Size = 18;
    const baseHeading3Size = 16;
    const baseHeading4Size = 14;
    const baseBodySize = 12;
    const baseLineHeight = 1.4;
    
    styleElement.textContent = `
      /* Scaled font sizes */
      body {
        font-size: ${baseBodySize * newScale}px !important;
      }
      
      h1 {
        font-size: ${baseHeading1Size * newScale}px !important;
      }
      
      h2 {
        font-size: ${baseHeading2Size * newScale}px !important;
      }
      
      h3 {
        font-size: ${baseHeading3Size * newScale}px !important;
      }
      
      h4 {
        font-size: ${baseHeading4Size * newScale}px !important;
      }
      
      p, li, span, div {
        font-size: ${baseBodySize * newScale}px !important;
        line-height: ${baseLineHeight} !important;
      }
      
      /* Scale margins and spacing proportionally */
      div, section {
        margin-top: ${4 * newScale}px !important;
        margin-bottom: ${4 * newScale}px !important;
      }
      
      p {
        margin-top: ${3 * newScale}px !important;
        margin-bottom: ${3 * newScale}px !important;
      }
      
      ul, ol {
        padding-left: ${18 * newScale}px !important;
        margin: ${4 * newScale}px 0 !important;
      }
      
      li {
        margin-bottom: ${4 * newScale}px !important;
      }
      
      section, .section, [data-section] {
        margin-bottom: ${12 * newScale}px !important;
        padding-bottom: ${8 * newScale}px !important;
      }
    `;
    
    iframeDocument.head.appendChild(styleElement);
       // Update page break indicators after scale change
       updatePageBreakIndicators(newScale);
    //console.log(`Applied text scale: ${newScale}`);
    
  } catch (err) {
    console.error("Error adjusting text scale:", err);
  }
}

// Add this component for the scale controls
const TextScaleControls = () => {
  if (!showScaleControls) return null;
  
  return (
    <div className="text-scale-controls">
      <button 
        onClick={() => adjustTextScale(Math.max(0.7, textScale - 0.1))}
        disabled={textScale <= 0.7}
        className="scale-btn"
      >
        -
      </button>
      <span className="scale-value">{Math.round(textScale * 100)}%</span>
      <button 
        onClick={() => adjustTextScale(Math.min(1.3, textScale + 0.1))}
        disabled={textScale >= 1.3}
        className="scale-btn"
      >
        +
      </button>
    </div>
  );
};


// Modify the fillTemplateWithData function to ensure proper container height
const fillTemplateWithData = (templateHtml, resumeData) => {
    if (!resumeData) {
      console.error("No resume data provided to fillTemplateWithData");
      return templateHtml;
    }
  
    try {
      /* console.log("Filling template with data", {
        hasName: !!resumeData.name,
        hasExperience: resumeData.experience?.length || 0,
        hasSkills: resumeData.skills?.length || 0
      }); */
      
      // Create a DOM parser
      const parser = new DOMParser();
      const doc = parser.parseFromString(templateHtml, 'text/html');
      
      // IMPORTANT: Set minimum height and overflow properties on the resume container
      // This ensures content can expand beyond one page if needed
      const resumeContainer = doc.querySelector('div[style*="max-width: 8.5in"]') || 
                            doc.querySelector('.resume-container');
      
      if (resumeContainer) {
        // Ensure container can grow beyond one page if needed
        resumeContainer.style.minHeight = '11in';
        resumeContainer.style.height = 'auto';
        resumeContainer.style.overflow = 'visible';
        resumeContainer.style.position = 'relative';
        
        // Add a small margin to the bottom to ensure content isn't cut off
        resumeContainer.style.marginBottom = '0.5in';
        
        //console.log("Set resume container to allow multi-page overflow");
      }
      
      // Name
      const nameElem = doc.querySelector('h1[data-gjs-editable="true"], h2[data-gjs-editable="true"]');
      if (nameElem && resumeData.name) {
        nameElem.textContent = resumeData.name;
      }
      
      // Contact info
      const contactElem = doc.querySelector('[data-section="contactInfo"]');
      if (contactElem && resumeData.contactInfo) {
        let contactHtml = '<div style="display: flex; flex-wrap: wrap; gap: 8px;">';
        
        if (resumeData.contactInfo.email) {
          contactHtml += `<span style="margin-right: 12px;">
            <strong>Email:</strong> ${resumeData.contactInfo.email}
          </span>`;
        }
        
        if (resumeData.contactInfo.phone) {
          contactHtml += `<span style="margin-right: 12px;">
            <strong>Phone:</strong> ${resumeData.contactInfo.phone}
          </span>`;
        }
        
        if (resumeData.contactInfo.linkedin) {
          contactHtml += `<span style="margin-right: 12px;">
            <strong>LinkedIn:</strong> ${resumeData.contactInfo.linkedin}
          </span>`;
        }
        
        if (resumeData.contactInfo.location) {
          contactHtml += `<span style="margin-right: 12px;">
            <strong>Location:</strong> ${resumeData.contactInfo.location}
          </span>`;
        }
        
        contactHtml += '</div>';
        contactElem.innerHTML = contactHtml;
      }
      
      // Summary
      const summaryElem = doc.querySelector('[data-section="summary"]');
      if (summaryElem && resumeData.summary) {
        summaryElem.innerHTML = resumeData.summary.replace(/\n/g, '<br>');
      }
      
   // In DocumentBuilderPage.jsx, find the fillTemplateWithData function
// Replace the Skills section with this print-optimized version:

// Skills
const skillsElem = doc.querySelector('[data-section="skills"]');
if (skillsElem && resumeData.skills && resumeData.skills.length > 0) {
  // Check if this is the classic template which needs columns
  const isClassicTemplate = selectedTemplate?.id === "classic" || 
                           templateHtml.includes('columns: 2');
  
  if (isClassicTemplate) {
    // For Classic template, create a more print-friendly two-column layout
    // Create a container div to hold two column divs
    let skillsHTML = '<div style="display: flex; width: 100%;">';
    
    // Calculate midpoint to divide skills into two columns
    const midpoint = Math.ceil(resumeData.skills.length / 2);
    const firstColumn = resumeData.skills.slice(0, midpoint);
    const secondColumn = resumeData.skills.slice(midpoint);
    
    // Create first column
    skillsHTML += '<div style="flex: 1; padding-right: 10px;">';
    skillsHTML += '<ul style="padding-left: 20px; margin: 5px 0;">';
    firstColumn.forEach(skill => {
      skillsHTML += `<li>${skill}</li>`;
    });
    skillsHTML += '</ul></div>';
    
    // Create second column
    skillsHTML += '<div style="flex: 1; padding-left: 10px;">';
    skillsHTML += '<ul style="padding-left: 20px; margin: 5px 0;">';
    secondColumn.forEach(skill => {
      skillsHTML += `<li>${skill}</li>`;
    });
    skillsHTML += '</ul></div>';
    
    // Close the container
    skillsHTML += '</div>';
    
    // Apply the HTML
    skillsElem.innerHTML = skillsHTML;
  } else {
    // For other templates, use standard single column
    skillsElem.innerHTML = '<ul style="padding-left: 20px; margin: 5px 0;">' +
      resumeData.skills.map(skill => `<li>${skill}</li>`).join('') +
      '</ul>';
  }
}
      
     // Replace the experience section in your fillTemplateWithData function with this code

// Experience
const experienceElem = doc.querySelector('[data-section="experience"]');
if (experienceElem && resumeData.experience && resumeData.experience.length > 0) {
  let expHtml = '';
  
  resumeData.experience.forEach((exp, index) => {
    expHtml += `<div style="margin-bottom: 15px;">`;
    expHtml += `<h4 style="margin-bottom: 5px;">${exp.title || ''} ${exp.company ? `at ${exp.company}` : ''}</h4>`;
    expHtml += `<p style="margin: 0 0 5px 0; font-style: italic;">${exp.dates || ''}</p>`;
    
    // Handle responsibilities with improved bullet point handling
    if (exp.responsibilities) {
      if (typeof exp.responsibilities === 'string') {
        // Check if it already contains HTML formatting
        if (exp.responsibilities.includes('<ul>') || exp.responsibilities.includes('<li>')) {
          // Already HTML formatted, use as is
          expHtml += exp.responsibilities;
        } else if (exp.responsibilities.includes('•') || exp.responsibilities.includes('-')) {
          // Convert bullet text to HTML list
          
          // Split by lines while preserving bullet groups
          // This regex matches lines that start with bullet characters
          const bulletSplit = exp.responsibilities.split(/\n(?=[•\-])/);
          
          expHtml += '<ul style="padding-left: 20px; margin: 5px 0;">';
          
          bulletSplit.forEach(bulletGroup => {
            if (!bulletGroup.trim()) return;
            
            // Clean the bullet text and replace the bullet character
            let bulletText = bulletGroup.trim().replace(/^[•\-]\s*/, '');
            
            // Process multi-line bullets (continued lines without bullet characters)
            const bulletLines = bulletText.split('\n');
            if (bulletLines.length > 1) {
              // If there are multiple lines in this bullet group, join them properly
              bulletText = bulletLines.join(' ');
            }
            
            // Ensure the bullet ends with proper punctuation
            if (bulletText && !['.', '!', '?'].includes(bulletText[bulletText.length - 1])) {
              bulletText += '.';
            }
            
            // Only add non-empty bullets
            if (bulletText.trim()) {
              expHtml += `<li>${bulletText}</li>`;
            }
          });
          
          expHtml += '</ul>';
        } else {
          // Regular paragraph text - convert to simple bullets
          const paragraphs = exp.responsibilities
            .split(/\n\n+/)
            .filter(p => p.trim().length > 0);
          
          if (paragraphs.length > 1) {
            expHtml += '<ul style="padding-left: 20px; margin: 5px 0;">';
            paragraphs.forEach(para => {
              if (para.trim()) {
                // Make sure paragraph ends with punctuation
                let formattedPara = para.trim();
                if (!['.', '!', '?'].includes(formattedPara[formattedPara.length - 1])) {
                  formattedPara += '.';
                }
                expHtml += `<li>${formattedPara}</li>`;
              }
            });
            expHtml += '</ul>';
          } else {
            // Single paragraph - add as is
            expHtml += `<p>${exp.responsibilities}</p>`;
          }
        }
      } else if (Array.isArray(exp.responsibilities)) {
        // Handle array of responsibilities
        expHtml += '<ul style="padding-left: 20px; margin: 5px 0;">';
        
        exp.responsibilities.forEach(resp => {
          if (resp && typeof resp === 'string') {
            let responsibilityText = resp.trim();
            
            // Ensure it ends with period
            if (responsibilityText && !['.', '!', '?'].includes(responsibilityText[responsibilityText.length - 1])) {
              responsibilityText += '.';
            }
            
            expHtml += `<li>${responsibilityText}</li>`;
          }
        });
        
        expHtml += '</ul>';
      }
    }
    
    expHtml += '</div>';
  });
  
  experienceElem.innerHTML = expHtml;
}
      
      // Education
      const educationElem = doc.querySelector('[data-section="education"]');
      if (educationElem && resumeData.education && resumeData.education.length > 0) {
        let eduHtml = '';
        
        resumeData.education.forEach(edu => {
          eduHtml += `<div style="margin-bottom: 15px;">`;
          eduHtml += `<h4 style="margin-bottom: 5px;">${edu.school || ''} ${edu.degree ? `- ${edu.degree}` : ''}</h4>`;
          eduHtml += `<p style="margin: 0 0 5px 0; font-style: italic;">${edu.dates || ''}</p>`;
          
          if (edu.gpa) {
            eduHtml += `<p><strong>GPA:</strong> ${edu.gpa}</p>`;
          }
          
          if (edu.achievements) {
            eduHtml += `<p>${edu.achievements}</p>`;
          }
          
          eduHtml += '</div>';
        });
        
        educationElem.innerHTML = eduHtml;
      }
      
      // Projects
      const projectsElem = doc.querySelector('[data-section="projects"]');
      if (projectsElem && resumeData.projects && resumeData.projects.length > 0) {
        let projHtml = '';
        
        resumeData.projects.forEach(proj => {
          projHtml += `<div style="margin-bottom: 15px;">`;
          projHtml += `<h4 style="margin-bottom: 5px;">${proj.name || 'Project'}</h4>`;
          
          if (proj.description) {
            projHtml += `<p>${proj.description}</p>`;
          }
          
          if (proj.technologies) {
            projHtml += `<p><strong>Technologies:</strong> ${proj.technologies}</p>`;
          }
          
          projHtml += '</div>';
        });
        
        projectsElem.innerHTML = projHtml;
      }
      
      // Set up container for proper page sizing and overflow handling
      // Find the main resume container
      const container = doc.querySelector('div[style*="max-width: 8.5in"]') || 
                       doc.querySelector('.resume-container');
      
      if (container) {
        // Ensure it has proper dimensions
        container.style.width = '8.5in';
        container.style.maxWidth = '8.5in';
        container.style.minHeight = '11in';
        container.style.position = 'relative';
        container.style.boxSizing = 'border-box';
        container.style.margin = '0 auto';
        container.style.backgroundColor = 'white';
        
         // Add these lines to remove the border
        container.style.border = 'none';
        container.style.boxShadow = 'none';

        // Add page break styles
        const styleElement = doc.createElement('style');
        styleElement.textContent = `
          @media print {
            @page {
              size: letter;
              margin: 0;
            }
            
            h1, h2, h3, h4 {
              page-break-after: avoid;
            }
            
            li, img, table {
              page-break-inside: avoid;
            }
          }
        `;
        doc.head.appendChild(styleElement);
      }
      
      // Log the final HTML for debugging
      //console.log("Template filled successfully, HTML length:", doc.documentElement.outerHTML.length);
      
      return doc.documentElement.outerHTML;
    } catch (err) {
      console.error('Error filling template:', err);
      return templateHtml; // Return original template if there's an error
    }
  };
  
  // Enhance PDF export with page overflow handling
  // Add this to your component before return() statement
  const originalHandleDownloadPDF = handleDownloadPDF;
  handleDownloadPDF = enhancePDFExportWithPageHandling(
    originalHandleDownloadPDF, 
    grapesEditorRef, 
    setLoading, 
    setError
  );
  
  // Add this hook to your component for automatic page overflow detection
  // Add near the top of your component with other useEffect hooks
  useEffect(() => {
    if (grapesEditorRef.current && finalHtml) {
      // Set up page overflow detection after content is loaded
      const cleanupOverflowDetection = setupContentOverflowDetection(grapesEditorRef);
      
      return () => {
        // Clean up overflow detection when component unmounts
        if (cleanupOverflowDetection) cleanupOverflowDetection();
      };
    }
  }, [grapesEditorRef.current, finalHtml]);
  
  // 2. Add toggleable view mode (To use this, you'll need to add UI elements)
  const [viewMode, setViewMode] = useState('full'); // 'full' or 'detail'
  
  async function enhanceExperienceEntriesWithAI() {
    if (!parsedResumeData?.experience) {
      console.warn("No experience data available");
      return parsedResumeData;
    }
    
    try {
      setLoading(true);
      setError("");
      
      // Debug log experience data with proper null checking
      //console.log("Enhancing experience entries:", parsedResumeData.experience.length);
      //console.log("API Key available:", !!OPENAI_API_KEY);
      
      // Log current formatting state with proper null checking
      /* console.log("Experience entries:", parsedResumeData.experience.map(exp => ({
        title: exp.title || 'Untitled',
        hasResponsibilities: !!exp.responsibilities,
        respType: typeof exp.responsibilities,
        respLength: typeof exp.responsibilities === 'string' ? exp.responsibilities.length : 0
      }))); */
      
      // Create formatter instance
      const formatter = new AIResumeFormatter(OPENAI_API_KEY);
      
      // Create progress indicator
      const progressInterval = setInterval(() => {
        setOptimizationProgress(prev => Math.min(prev + 5, 95));
      }, 200);
      
      // Process each experience entry in parallel
      const enhancedExperience = await Promise.all(
        parsedResumeData.experience.map(async (exp, index) => {
          // IMPORTANT: Convert non-string responsibilities to string
          if (exp.responsibilities && typeof exp.responsibilities !== 'string') {
            //console.log(`Entry ${index + 1}: Converting non-string responsibilities to string`);
            
            // If it's an array, join it
            if (Array.isArray(exp.responsibilities)) {
              exp.responsibilities = exp.responsibilities.join('\n');
            } else {
              // Try to convert object to string, or use a default
              try {
                exp.responsibilities = JSON.stringify(exp.responsibilities);
              } catch (err) {
                exp.responsibilities = `Responsibilities for ${exp.title || 'this position'}`;
              }
            }
          }
          
          // Handle missing responsibilities by creating a placeholder
          if (!exp.responsibilities) {
            //console.log(`Entry ${index + 1}: No responsibilities field found, generating placeholder`);
            
            // Create a basic responsibility text from title and company
            const placeholderText = `Worked as ${exp.title || 'professional'} ${exp.company ? `at ${exp.company}` : ''}, handling various responsibilities and tasks related to the position.`;
            
            // Format this basic text
            try {
              const formattedPlaceholder = await formatter.formatResponsibilities(placeholderText);
              return { ...exp, responsibilities: formattedPlaceholder };
            } catch (err) {
              console.error(`Error formatting placeholder for entry ${index + 1}:`, err);
              return { ...exp, responsibilities: `• ${placeholderText}` };
            }
          }
          
          // Now we know responsibilities exists as a string, proceed with formatting
          try {
            //console.log(`Formatting experience entry ${index + 1}/${parsedResumeData.experience.length}`);
            
            // Only call substring if it's a string (should always be true now)
            if (typeof exp.responsibilities === 'string') {
              //console.log(`Entry ${index + 1} sample:`, exp.responsibilities.substring(0, 50) + "...");
            }
            
            // Execute formatting
            const formattedResp = await formatter.formatResponsibilities(exp.responsibilities);
            
            if (formattedResp && formattedResp.length > 0) {
              //console.log(`Entry ${index + 1} successfully formatted`);
              
              // Log before/after
              if (typeof exp.responsibilities === 'string' && typeof formattedResp === 'string') {
                //console.log(`Before: ${exp.responsibilities.substring(0, 50)}...`);
                //console.log(`After: ${formattedResp.substring(0, 50)}...`);
              }
              
              return { ...exp, responsibilities: formattedResp };
            } else {
              console.warn(`Entry ${index + 1}: Formatter returned empty string`);
            }
          } catch (err) {
            console.error(`Error formatting entry ${index + 1}:`, err);
          }
          
          return exp;
        })
      );
      
      // Create enhanced data object
      const enhancedData = {
        ...parsedResumeData,
        experience: enhancedExperience
      };
      
      // Log result summary - safe version that doesn't call substring on non-strings
      /* console.log("Experience entries after formatting:", enhancedData.experience.map(exp => ({
        title: exp.title || 'Untitled',
        hasResponsibilities: !!exp.responsibilities,
        respType: typeof exp.responsibilities,
        respLength: typeof exp.responsibilities === 'string' ? exp.responsibilities.length : 0
      })));
       */
      // Update state and template
      setParsedResumeData(enhancedData);
      
      // Complete progress
      clearInterval(progressInterval);
      setOptimizationProgress(100);
      
      // Show success message
      setError("Resume successfully formatted!");
      
      setTimeout(() => {
        setOptimizationProgress(0);
        setLoading(false);
        setError("");
      }, 2000);
      
      return enhancedData;
    } catch (err) {
      console.error("Error in AI enhancement:", err);
      setError(`Error formatting resume: ${err.message}`);
      setLoading(false);
      setOptimizationProgress(0);
      return parsedResumeData;
    }
  }
  


  const handleFormatWithAI = async () => {
    try {
      // Set loading state
      setLoading(true);
      setError("Processing your resume...");
      
      // Log initial state
      //console.log("Starting AI formatting process");
      //console.log("Current template:", selectedTemplate?.id);
      //console.log("Has parsed data:", !!parsedResumeData);
      
      // Format the experience entries
      const enhancedData = await enhanceExperienceEntriesWithAI();
      
      // Check if we got valid data back
      if (!enhancedData || !selectedTemplate) {
        setError("Failed to format resume. Please try again.");
        setLoading(false);
        return;
      }
      
      // Update parsed data state
      setParsedResumeData(enhancedData);
      
      // Get template HTML
      //console.log("Getting template HTML");
      const templateHtml = selectedTemplate.getHTML();
      
      // Fill with enhanced data
      //console.log("Filling template with formatted data");
      const filledHtml = fillTemplateWithData(templateHtml, enhancedData);
      
      // Verify filled HTML
      if (filledHtml.length <= templateHtml.length) {
        console.warn("Filled HTML is not longer than template HTML, possible filling error");
        //console.log("Template HTML length:", templateHtml.length);
        //console.log("Filled HTML length:", filledHtml.length);
      }
      
      // Update the HTML state
      //console.log("Setting final HTML");
      setFinalHtml(filledHtml);
      
      // Initialize the editor with the new content
      //console.log("Initializing editor with filled content");
      initializeEditor(filledHtml);
      
      // Apply font size adjustments after a short delay to ensure editor is loaded
 /*      setTimeout(() => {
        adjustTemplateFontSizes();
      }, 500); */
      
      // Show success message
      setError("Resume successfully formatted!");
      
      // Reset states after delay
      setTimeout(() => {
        setLoading(false);
        setError("");
      }, 2000);
    } catch (error) {
      console.error("Error in format with AI:", error);
      setError(`Error: ${error.message}`);
      setLoading(false);
    }
  }
  

  
  // 5. Updated ActionHeader component with format button (replace your current header render)
  const ActionHeader = () => (
    <div className={`action-header ${isMobileView ? 'mobile-header' : 'desktop-header'}`}>
      <h2>AI Resume Builder</h2>
      
      <div className="action-buttons">
        {/* Add the Stripe Subscription Button here */}
      <StripeSubscriptionButton />
        {isMobileView && (
          <button 
            className="action-btn template-selector-btn" 
            onClick={toggleTemplates}
          >
            {showTemplates ? "Hide Templates" : "Choose Template"}
          </button>
        )}
        
       
        
        <div className="dropdown">
          <button className="action-btn dropdown-btn">Export</button>
          <div className="dropdown-content">
            <button onClick={handleDownloadPDF}>PDF</button>
            <button onClick={handleDownloadRTF}>Word Document</button>
          </div>
        </div>
      </div>
      
      {error && <div className="error-message">{error}</div>}
    </div>
  );
  
  // 6. Add CSS for the format button (add to your DocumentBuilderPage.css)
  /*
  .format-btn {
    background-color: #6c6cff;
  }
  
  .format-btn:hover {
    background-color: #5252e0;
  }
  */


  const handleOptimize = async () => {
    if (!originalResume || !selectedTemplate) {
      setError("No resume content or template selected.");
      return;
    }
  
    try {
      setLoading(true);
      setError("");
      //console.log("Starting resume optimization with template:", selectedTemplate.id);
      
      // Parse the resume if not already parsed
      let resumeData = parsedResumeData;
      if (!resumeData) {
        //console.log("No parsed data available, parsing resume...");
        resumeData = await parseResume();
        
        if (!resumeData) {
          throw new Error("Failed to parse resume data");
        }
        
        setParsedResumeData(resumeData);
      }
      
      /* console.log("Resume data available:", 
        resumeData.experience ? `${resumeData.experience.length} experience entries` : "no experience data"); */
      
      // Try to enhance experience entries
      try {
        const enhancedData = await enhanceExperienceEntries(resumeData);
        resumeData = enhancedData || resumeData;
      } catch (formatError) {
        console.error("Error in AI formatting, using original data:", formatError);
      }
      
      // Update parsed data state
      setParsedResumeData(resumeData);
      
      // Get the template HTML
      //console.log("Getting template HTML for:", selectedTemplate.id);
      const templateHtml = selectedTemplate.getHTML();
      
      // Fill the template with data
      //console.log("Filling template with resume data");
      const filledHtml = fillTemplateWithData(templateHtml, resumeData);
      
      // Verify filled HTML
      if (filledHtml.length <= templateHtml.length) {
        console.warn("Filled HTML is not longer than template HTML, possible filling error");
      }
      
      //console.log('Filled template HTML generated, length:', filledHtml.length);
      
      // Update state with filled HTML
      setFinalHtml(filledHtml);
      
      // Force setting the HTML to editor directly
      const editor = grapesEditorRef.current;
      if (editor) {
        //console.log("Directly setting editor content");
        editor.setComponents(filledHtml);
        updateHtmlAndPreview(editor.getHtml());
      } else {
        //console.log("Reinitializing editor with filled content");
        setTimeout(() => {
          initializeEditor(filledHtml);
        }, 300);
      }
      
    } catch (err) {
      console.error("Error optimizing resume:", err);
      setError(`Failed to optimize resume: ${err.message}`);
    } finally {
      setLoading(false);
      setShowParseModal(false);
    }
  };

// Updated adjustTemplateFontSizes function with better font sizing
function adjustTemplateFontSizes() {
    try {
      // Get the editor instance
      const editor = grapesEditorRef.current;
      if (!editor) {
        console.warn("Editor not initialized");
        return;
      }
      
      // Get the iframe document
      const iframe = editor.Canvas.getFrameEl();
      if (!iframe) {
        console.warn("Cannot find editor frame");
        return;
      }
      
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      if (!iframeDocument) {
        console.warn("Cannot access iframe document");
        return;
      }
      
      // Apply font size adjustments - more compact approach for better fitting
      const styleElement = iframeDocument.createElement('style');
      styleElement.textContent = `
        /* Apply compact font size adjustments */
        body {
          font-size: 14px !important;
        }
        
        h1 {
          font-size: 24px !important;
          margin-top: 10px !important;
          margin-bottom: 8px !important;
        }
        
        h2 {
          font-size: 20px !important;
          margin-top: 8px !important;
          margin-bottom: 6px !important;
        }
        
        h3 {
          font-size: 18px !important;
          margin-top: 6px !important;
          margin-bottom: 4px !important;
        }
        
        h4 {
          font-size: 16px !important;
          margin-top: 4px !important;
          margin-bottom: 3px !important;
        }
        
        p, li, span, div {
          font-size: 14px !important;
          line-height: 1.3 !important;
        }
        
        /* Reduce margins and padding for better space usage */
        div, section, p {
          margin-top: 3px !important;
          margin-bottom: 3px !important;
        }
        
        /* Tighten list spacing */
        ul, ol {
          padding-left: 18px !important;
          margin: 3px 0 !important;
        }
        
        li {
          margin-bottom: 2px !important;
        }
        
        /* Remove border from main container */
        div[style*="max-width: 8.5in"], .resume-container {
          border: none !important;
          box-shadow: none !important;
        }
        
        /* Reduce paragraph spacing */
        p + p {
          margin-top: 3px !important;
        }
        
        /* Adjust section spacing */
        section, .section {
          margin-bottom: 8px !important;
          padding-bottom: 5px !important;
        }
      `;
      
      iframeDocument.head.appendChild(styleElement);
      
      //console.log("Template font sizes adjusted for better fit and readability");
      
      // Set the content background to white and remove borders
      const resumeContainer = iframeDocument.querySelector('div[style*="max-width: 8.5in"]') || 
                             iframeDocument.querySelector('.resume-container');
      if (resumeContainer) {
        resumeContainer.style.backgroundColor = 'white';
        resumeContainer.style.border = 'none';
        resumeContainer.style.boxShadow = 'none';
      }
      
    } catch (err) {
      console.error("Error adjusting template font sizes:", err);
    }
  }

// Fixed PDF export function with better container detection
// Fixed PDF export function with better container detection and no borders
async function handleDownloadPDF() {
    try {
      setLoading(true);
      setError("Preparing PDF...");
      
      // Get the editor instance
      const editor = grapesEditorRef.current;
      if (!editor) {
        throw new Error("Editor not initialized");
      }
      
      // Get the iframe containing editor content
      const iframe = editor.Canvas.getFrameEl();
      if (!iframe) {
        throw new Error("Cannot find editor frame");
      }
      
      // Access the iframe document
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      if (!iframeDocument) {
        throw new Error("Cannot access iframe document");
      }
      
      // Hide all page break indicators
      const indicators = iframeDocument.querySelectorAll('.page-break-indicator, .page-number-indicator');
      indicators.forEach(indicator => {
        indicator.style.display = 'none';
      });
      
      // Get raw HTML content as fallback
      const rawHtml = editor.getHtml();
      
      // Improved container detection with multiple possible selectors
      let resumeContainer = null;
      let containerHtml = '';
      
      // Try multiple selectors to find the container
      const possibleSelectors = [
        'div[style*="max-width: 8.5in"]',
        '.resume-container',
        '#resume-content',
        'div[style*="width: 8.5in"]',
        '.gjs-frame div',
        'body > div'
      ];
      
      // Try each selector
      for (const selector of possibleSelectors) {
        const elements = iframeDocument.querySelectorAll(selector);
        
        // If we found elements, use the largest one
        if (elements.length > 0) {
          let largestEl = elements[0];
          let maxHeight = largestEl.offsetHeight;
          
          for (let i = 1; i < elements.length; i++) {
            if (elements[i].offsetHeight > maxHeight) {
              maxHeight = elements[i].offsetHeight;
              largestEl = elements[i];
            }
          }
          
          // If we found a large enough element, use it
          if (maxHeight > 300) {
            resumeContainer = largestEl;
            //console.log(`Found container using selector: ${selector}, height: ${maxHeight}px`);
            break;
          }
        }
      }
      
      // If we still haven't found it, try the body itself
      if (!resumeContainer) {
        resumeContainer = iframeDocument.body;
        //console.log("Using document body as container");
      }
      
      // Get the container HTML
      if (resumeContainer) {
        containerHtml = resumeContainer.innerHTML;
        
        // If container is empty or very small, use raw HTML as fallback
        if (containerHtml.length < 100) {
          //console.log("Container appears empty, using raw HTML");
          containerHtml = rawHtml;
        }
      } else {
        // Fallback to raw HTML if no container found
        //console.log("No container found, using raw HTML");
        containerHtml = rawHtml;
      }
      
      // Create a new window for the export
      const exportWindow = window.open('', '_blank', 'width=850,height=800');
      if (!exportWindow) {
        throw new Error("Unable to open export window. Please check your popup blocker settings.");
      }
      
      // Get all styles from the iframe
      let allStyles = '';
      
      // Get inline styles
      const styleElements = iframeDocument.querySelectorAll('style');
      for (const style of styleElements) {
        allStyles += style.innerHTML;
      }
      
      // Write content to the new window
      exportWindow.document.open();
      exportWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Resume Export</title>
          <style>
            /* Base styles */
            body {
              margin: 0;
              padding: 0;
              background-color: #f8f8f8;
              font-family: Arial, sans-serif;
            }
            
            .wrapper {
              max-width: 1000px;
              margin: 20px auto;
              padding: 20px;
            }
            
            .export-controls {
              background-color: white;
              padding: 15px;
              margin-bottom: 20px;
              border-radius: 5px;
              box-shadow: 0 2px 5px rgba(0,0,0,0.1);
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            
            .export-btn {
              padding: 10px 20px;
              background-color: #4285f4;
              color: white;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              font-size: 16px;
              font-weight: 500;
            }
            
            .export-btn:hover {
              background-color: #3367d6;
            }
            
            .cancel-btn {
              padding: 10px 20px;
              background-color: #f44336;
              color: white;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              font-size: 16px;
              font-weight: 500;
              margin-left: 10px;
            }
            
            .cancel-btn:hover {
              background-color: #d32f2f;
            }
            
            .status {
              margin-left: 20px;
              font-size: 14px;
            }
            
            .resume-container {
              width: 8.5in;
              max-width: 100%;
              margin: 0 auto;
              background-color: white;
              padding: 0;
              box-sizing: border-box;
              position: relative;
              border: none !important;
              box-shadow: none !important;
            }
            
            /* Critical fix for bullet points getting split */
            .resume-container ul,
            .resume-container ol {
              padding-left: 1.5em;
              margin: 0.5em 0;
            }
            
            .resume-container li {
              margin-bottom: 0.25em; 
              page-break-inside: avoid;
            }
            
            /* Print styles */
            @media print {
              body {
                background: white;
                margin: 0;
                padding: 0;
                box-shadow: none;
              }
              
              .wrapper {
                margin: 0;
                padding: 0;
                max-width: none;
              }
              
              .export-controls {
                display: none;
              }
              
              .resume-container {
                width: 100%;
                padding: 0;
                box-shadow: none;
                margin: 0;
                border: none !important;
              }
              
              @page {
                size: letter;
                margin: 0;
              }
              
              /* Prevent unwanted breaks */
              h1, h2, h3, h4, h5, h6 {
                page-break-after: avoid;
              }
              
              li, p {
                page-break-inside: avoid;
              }
            }
            
            /* Remove all page break indicators permanently */
            .page-break-indicator,
            .page-number-indicator {
              display: none !important;
            }
            
            /* Remove all borders and white space */
            * {
              border: none !important;
              box-shadow: none !important;
            }
            
            div[style*="max-width: 8.5in"],
            .resume-container,
            div[style*="width: 8.5in"] {
              border: none !important;
              box-shadow: none !important;
              margin: 0 !important;
              padding: 0 !important;
            }
            
            /* Original styles from document */
            ${allStyles}
          </style>
        </head>
        <body>
          <div class="wrapper">
            <div class="export-controls">
              <div>
                <button id="export-btn" class="export-btn">Download as PDF</button>
                <button id="cancel-btn" class="cancel-btn">Close</button>
              </div>
              <div id="status" class="status"></div>
            </div>
            
            <div class="resume-container"></div>
          </div>
          
          <script>
            // Insert content
            document.querySelector('.resume-container').innerHTML = \`${containerHtml.replace(/`/g, '\\`')}\`;
            
            // Handle close button
            document.getElementById('cancel-btn').addEventListener('click', function() {
              window.close();
            });
            
            // Remove any remaining indicators
            document.querySelectorAll('.page-break-indicator, .page-number-indicator').forEach(el => {
              el.remove();
            });
            
            // Remove all borders and white space
            document.querySelectorAll('[style*="border"], [style*="box-shadow"], [style*="margin"], [style*="padding"]').forEach(el => {
              el.style.border = 'none';
              el.style.boxShadow = 'none';
              // Keep some padding for content readability but remove margins
              if (!el.tagName || (el.tagName !== 'P' && el.tagName !== 'LI' && el.tagName !== 'UL' && el.tagName !== 'OL')) {
                el.style.padding = '0';
              }
              el.style.margin = '0';
            });
            
            // Fix bullet points that might have been split
            function fixBrokenBulletPoints() {
              // Find all bullet points
              const bullets = document.querySelectorAll('.resume-container li');
              
              // Check each bullet for ending punctuation
              bullets.forEach(bullet => {
                const text = bullet.textContent.trim();
                
                // If bullet doesn't end with punctuation and the next element is also a bullet
                if (
                  !text.endsWith('.') && 
                  !text.endsWith('!') && 
                  !text.endsWith('?') && 
                  bullet.nextElementSibling && 
                  bullet.nextElementSibling.tagName === 'LI'
                ) {
                  // Check if the next bullet starts with lowercase (likely a continuation)
                  const nextText = bullet.nextElementSibling.textContent.trim();
                  if (nextText.length > 0 && /^[a-z]/.test(nextText[0])) {
                    // Combine the bullets
                    bullet.textContent = text + ' ' + nextText;
                    bullet.nextElementSibling.style.display = 'none';
                  }
                }
              });
            }
            
            // Fix split paragraphs and sections
            function fixSplitParagraphs() {
              // Look for paragraphs that end without punctuation
              const paragraphs = document.querySelectorAll('.resume-container p');
              
              paragraphs.forEach(para => {
                const text = para.textContent.trim();
                
                // If paragraph doesn't end with punctuation and next element is also a paragraph
                if (
                  !text.endsWith('.') && 
                  !text.endsWith('!') && 
                  !text.endsWith('?') && 
                  !text.endsWith(':') && 
                  para.nextElementSibling && 
                  para.nextElementSibling.tagName === 'P'
                ) {
                  // Check if the next paragraph starts with lowercase (likely a continuation)
                  const nextText = para.nextElementSibling.textContent.trim();
                  if (nextText.length > 0 && /^[a-z]/.test(nextText[0])) {
                    // Combine the paragraphs
                    para.textContent = text + ' ' + nextText;
                    para.nextElementSibling.style.display = 'none';
                  }
                }
              });
            }
            
            // Run fixes
            setTimeout(() => {
              fixBrokenBulletPoints();
              fixSplitParagraphs();
            }, 300);
            
            // Export as PDF
            document.getElementById('export-btn').addEventListener('click', function() {
              const statusEl = document.getElementById('status');
              statusEl.textContent = 'Generating PDF...';
              
              // Apply additional fixes right before printing
              document.querySelectorAll('div[style*="max-width: 8.5in"], .resume-container').forEach(el => {
                el.style.border = 'none';
                el.style.boxShadow = 'none';
                el.style.margin = '0';
                el.style.padding = '0';
              });
              
              // Print the page which allows saving as PDF
              setTimeout(() => {
                window.print();
                statusEl.textContent = 'Ready to download. If the print dialog closed, click the button again.';
              }, 500);
            });
            
            // Automatically trigger the export after a delay
            setTimeout(() => {
              document.getElementById('export-btn').click();
            }, 1000);
          </script>
        </body>
        </html>
      `);
      exportWindow.document.close();
      
      // Restore indicators in the editor
      indicators.forEach(indicator => {
        indicator.style.display = '';
      });
      
      setLoading(false);
      setError("Export window opened. Click 'Download as PDF' if it doesn't automatically start.");
      
      setTimeout(() => {
        setError("");
      }, 4000);
      
    } catch (err) {
      console.error("PDF export error:", err);
      setError(`PDF export failed: ${err.message}`);
      setLoading(false);
    }
  }

  
// Fixed handleDirectPDFExport function with reliable print triggering
async function handleDirectPDFExport() {
  try {
    setLoading(true);
    setError("Preparing your resume for download...");
    
    // Get the editor instance
    const editor = grapesEditorRef.current;
    if (!editor) {
      throw new Error("Editor not initialized");
    }
    
    // Get the iframe containing editor content
    const iframe = editor.Canvas.getFrameEl();
    if (!iframe) {
      throw new Error("Cannot find editor frame");
    }
    
    // Access the iframe document
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    if (!iframeDocument) {
      throw new Error("Cannot access iframe document");
    }
    
    // Get the resume content - try multiple selectors
    let resumeContainer = null;
    const possibleSelectors = [
      'div[style*="max-width: 8.5in"]',
      '.resume-container',
      '#resume-content',
      'div[style*="width: 8.5in"]',
      '.gjs-frame div',
      'body > div'
    ];
    
    for (const selector of possibleSelectors) {
      const elements = iframeDocument.querySelectorAll(selector);
      if (elements.length > 0) {
        let largestEl = elements[0];
        let maxHeight = largestEl.offsetHeight;
        
        for (let i = 1; i < elements.length; i++) {
          if (elements[i].offsetHeight > maxHeight) {
            maxHeight = elements[i].offsetHeight;
            largestEl = elements[i];
          }
        }
        
        if (maxHeight > 300) {
          resumeContainer = largestEl;
          break;
        }
      }
    }
    
    if (!resumeContainer) {
      resumeContainer = iframeDocument.body;
    }
    
    // Get just the content HTML - no headers, footers, or other UI elements
    const resumeHTML = resumeContainer.innerHTML;
    
    // Get styles from the document
    let styleContent = '';
    const styleElements = iframeDocument.querySelectorAll('style');
    for (const style of styleElements) {
      styleContent += style.innerHTML;
    }
    
    // Create a new window to display a clean version for PDF download
    const printWindow = window.open('', '_blank', 'width=850,height=950');
    if (!printWindow) {
      throw new Error("Unable to open print window. Please check your popup blocker settings.");
    }
    
    // Create a print-specific style to ensure no borders
    const printStyle = document.createElement('style');
    printStyle.textContent = `
      @page { margin: 0; size: letter; }
      body { margin: 0; padding: 0; }
      * { border: none !important; box-shadow: none !important; }
    `;
    
    // Write a clean HTML document with only the resume content
    printWindow.document.open();
    printWindow.document.write(`<!DOCTYPE html>
<html>
<head>
  <title>Resume Download</title>
  <style>
    /* Reset & Basic Styles */
    body, html {
      margin: 0;
      padding: 0;
      font-family: Arial, sans-serif;
      background-color: white;
    }
    
    /* Print Optimization */
    @page {
      size: letter;
      margin: 0;
    }
    
    @media print {
      body {
        margin: 0;
        padding: 0;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      
      .controls {
        display: none !important;
      }
    }
    
    /* Control Buttons */
    .controls {
      position: fixed;
      top: 10px;
      right: 10px;
      background-color: #f5f5f5;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
      z-index: 9999;
    }
    
    .btn {
      background-color: #4285f4;
      color: white;
      border: none;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
      font-size: 14px;
      margin: 0 5px;
    }
    
    .btn:hover {
      background-color: #3367d6;
    }
    
    /* Content Container - NO BORDERS */
    .resume-container {
      width: 100%;
      max-width: 8.5in;
      margin: 0 auto;
      padding: 0;
      background-color: white;
      border: none;
      box-shadow: none;
    }
    
    /* Original document styles */
    ${styleContent}
    
    /* Override all borders */
    * {
      border: none !important;
      box-shadow: none !important;
    }
  </style>
</head>
<body>
  <div class="controls">
    <button id="print-btn" class="btn">Print / Save PDF</button>
    <button id="close-btn" class="btn">Close</button>
  </div>

  <div class="resume-container">${resumeHTML}</div>

  <script>
    // Remove any page break indicators or UI elements
    document.querySelectorAll('.page-break-indicator, .page-number-indicator').forEach(el => {
      el.remove();
    });
    
    // Setup print button
    document.getElementById('print-btn').addEventListener('click', function() {
      // Hide controls before printing
      document.querySelector('.controls').style.display = 'none';
      
      // Add a print-specific style element
      const printStyle = document.createElement('style');
      printStyle.textContent = \`
        @page { margin: 0; size: letter; }
        body { margin: 0; padding: 0; }
        * { border: none !important; box-shadow: none !important; }
      \`;
      document.head.appendChild(printStyle);
      
      // Start print process
      window.print();
      
      // Show controls again after printing
      setTimeout(() => {
        document.querySelector('.controls').style.display = 'block';
        document.head.removeChild(printStyle);
      }, 1000);
    });
    
    // Setup close button
    document.getElementById('close-btn').addEventListener('click', function() {
      window.close();
    });
    
    // Remove all borders from elements
    document.querySelectorAll('*[style*="border"], *[style*="box-shadow"]').forEach(el => {
      if (el.style) {
        el.style.border = 'none';
        el.style.boxShadow = 'none';
      }
    });
  </script>
</body>
</html>`);
    printWindow.document.close();
    
    setLoading(false);
    setError("Resume prepared for printing. Click 'Print / Save PDF' in the new window.");
    
    setTimeout(() => {
      setError("");
    }, 5000);
    
  } catch (err) {
    console.error("PDF preparation error:", err);
    setError("PDF preparation failed: " + err.message);
    setLoading(false);
  }
}
  
// Simpler PDF export function - creates a cleaner document for printing
async function handleDownloadPDF() {
  try {
    setLoading(true);
    setError("Preparing PDF...");
    
    // Get the editor instance
    const editor = grapesEditorRef.current;
    if (!editor) {
      throw new Error("Editor not initialized");
    }
    
    // Get iframe document
    const iframe = editor.Canvas.getFrameEl();
    if (!iframe) {
      throw new Error("Cannot find editor frame");
    }
    
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    if (!iframeDocument) {
      throw new Error("Cannot access iframe document");
    }
    
    // Find the resume container
    const resumeContainer = iframeDocument.querySelector('div[style*="max-width: 8.5in"]') || 
                           iframeDocument.querySelector('.resume-container') ||
                           iframeDocument.body;
    
    // Create a clean HTML document for printing
    const contentHtml = resumeContainer.innerHTML;
    const cleanHtml = `<!DOCTYPE html>
<html>
<head>
  <title>Resume PDF</title>
  <style>
    @page { size: letter; margin: 0; }
    body { margin: 0; padding: 0; font-family: Arial, sans-serif; }
    .resume { max-width: 8.5in; margin: 0 auto; border: none !important; box-shadow: none !important; }
    * { border: none !important; box-shadow: none !important; }
  </style>
</head>
<body>
  <div class="resume">${contentHtml}</div>
</body>
</html>`;
    
    // Create a data URL or Blob URL
    const blob = new Blob([cleanHtml], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(blob);
    
    // Now offer direct download or open in new window
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = 'resume.html';
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    
    // Also try to open in a new window for printing
    window.open(blobUrl, '_blank');
    
    setLoading(false);
    setError("Resume has been downloaded. Open the HTML file and use your browser's Print function to save as PDF.");
    
    setTimeout(() => {
      setError("");
    }, 5000);
    
  } catch (err) {
    console.error("PDF export error:", err);
    setError(`PDF export failed: ${err.message}`);
    setLoading(false);
  }
}
  
  
  
  
  
  // Helper function to load required libraries
  async function loadRequiredLibraries() {
    // Check if libraries are already loaded
    if (window.html2canvas && (window.jsPDF || window.jspdf)) {
      //console.log("PDF libraries already loaded");
      return;
    }
    
    //console.log("Loading PDF libraries...");
    
    // Function to load a script
    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = resolve;
        script.onerror = () => reject(new Error("Failed to load script: " + url));
        document.head.appendChild(script);
      });
    };
    
    try {
      // Load html2canvas
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js');
      //console.log("html2canvas loaded");
      
      // Load jsPDF
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js');
      //console.log("jsPDF loaded");
      
      // Verify libraries loaded
      if (!window.html2canvas) {
        throw new Error("html2canvas failed to initialize properly");
      }
      
      if (!window.jspdf && !window.jsPDF) {
        throw new Error("jsPDF failed to initialize properly");
      }
      
      //console.log("All PDF libraries loaded successfully");
    } catch (err) {
      console.error("Error loading libraries:", err);
      throw new Error("Failed to load required libraries: " + err.message);
    }
  }
  
  // Helper function to load required libraries
  async function loadRequiredLibraries() {
    // Check if libraries are already loaded
    if (window.html2canvas && (window.jsPDF || window.jspdf)) {
      //console.log("PDF libraries already loaded");
      return;
    }
    
    //console.log("Loading PDF libraries...");
    
    // Function to load a script
    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = resolve;
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
        document.head.appendChild(script);
      });
    };
    
    try {
      // Load html2canvas
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js');
      //console.log("html2canvas loaded");
      
      // Load jsPDF
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js');
      //console.log("jsPDF loaded");
      
      // Verify libraries loaded
      if (!window.html2canvas) {
        throw new Error("html2canvas failed to initialize properly");
      }
      
      if (!window.jspdf && !window.jsPDF) {
        throw new Error("jsPDF failed to initialize properly");
      }
      
      //console.log("All PDF libraries loaded successfully");
    } catch (err) {
      console.error("Error loading libraries:", err);
      throw new Error(`Failed to load required libraries: ${err.message}`);
    }
  }
  
  // Helper function to load required libraries
  async function loadRequiredLibraries() {
    // Check if libraries are already loaded
    if (window.html2canvas && (window.jsPDF || window.jspdf)) {
      //console.log("PDF libraries already loaded");
      return;
    }
    
    //console.log("Loading PDF libraries...");
    
    // Function to load a script
    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = resolve;
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
        document.head.appendChild(script);
      });
    };
    
    try {
      // Load html2canvas
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js');
      //console.log("html2canvas loaded");
      
      // Load jsPDF
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js');
      //console.log("jsPDF loaded");
      
      // Verify libraries loaded
      if (!window.html2canvas) {
        throw new Error("html2canvas failed to initialize properly");
      }
      
      if (!window.jspdf && !window.jsPDF) {
        throw new Error("jsPDF failed to initialize properly");
      }
      
      //console.log("All PDF libraries loaded successfully");
    } catch (err) {
      console.error("Error loading libraries:", err);
      throw new Error(`Failed to load required libraries: ${err.message}`);
    }
  }

// Improved function with better container detection
function updatePageBreakIndicators(scale = 1.0) {
    try {
      // Get the editor instance
      const editor = grapesEditorRef.current;
      if (!editor) {
        console.warn("Editor not initialized");
        return;
      }
      
      // Get the iframe document
      const iframe = editor.Canvas.getFrameEl();
      if (!iframe) {
        console.warn("Cannot find editor frame");
        return;
      }
      
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      if (!iframeDocument) {
        console.warn("Cannot access iframe document");
        return;
      }
      
      // Find the resume container using multiple approaches
      let resumeContainer = null;
      
      // Try standard selectors first
      resumeContainer = iframeDocument.querySelector('div[style*="max-width: 8.5in"]');
      
      // If not found, try by class
      if (!resumeContainer) {
        resumeContainer = iframeDocument.querySelector('.resume-container');
      }
      
      // If still not found, try finding the largest div
      if (!resumeContainer) {
        const bodyDivs = iframeDocument.querySelectorAll('body > div');
        let largestDiv = null;
        let maxHeight = 0;
        
        bodyDivs.forEach(div => {
          const height = div.offsetHeight;
          if (height > maxHeight) {
            maxHeight = height;
            largestDiv = div;
          }
        });
        
        if (largestDiv && maxHeight > 500) {
          resumeContainer = largestDiv;
        }
      }
      
      // Last resort - use the body itself
      if (!resumeContainer) {
        resumeContainer = iframeDocument.body;
      }
      
      if (!resumeContainer) {
        console.error("Cannot find any suitable container");
        return;
      }
      
      // CRITICAL: Force position relative on container
      resumeContainer.style.position = 'relative';
      
      // Remove any existing page break indicators
      const existingIndicators = iframeDocument.querySelectorAll('.page-break-indicator, .page-number-indicator');
      existingIndicators.forEach(indicator => indicator.remove());
      
      // Standard US Letter page dimensions (in pixels at 96 DPI)
      const PAGE_HEIGHT_PX = 11 * 96; // 11in * 96px/in = 1056px
      
      // Get content height to determine number of pages
      const contentHeight = resumeContainer.scrollHeight;
      const numberOfPages = Math.ceil(contentHeight / PAGE_HEIGHT_PX);
      
      if (numberOfPages > 1) {
        // Create a style element for page break indicators
        let pageBreakStyle = iframeDocument.querySelector('#page-break-styles');
        if (!pageBreakStyle) {
          pageBreakStyle = iframeDocument.createElement('style');
          pageBreakStyle.id = 'page-break-styles';
          iframeDocument.head.appendChild(pageBreakStyle);
        }
        
        // Update the styles with more subtle appearance
        pageBreakStyle.textContent = `
          .page-break-indicator {
            position: absolute !important;
            left: 0 !important;
            right: 0 !important;
            height: 1px !important; 
            background: repeating-linear-gradient(to right, #aaaaaa, #aaaaaa 5px, transparent 5px, transparent 10px) !important;
            z-index: 9999 !important;
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
            pointer-events: none !important;
            opacity: 0.5 !important;
          }
          
          .page-break-label {
            position: absolute !important;
            right: 20px !important;
            top: -15px !important;
            background: rgba(170, 170, 170, 0.2) !important;
            color: #666666 !important;
            font-size: 11px !important;
            font-weight: normal !important;
            padding: 2px 6px !important;
            border: 1px solid rgba(170, 170, 170, 0.3) !important;
            border-radius: 3px !important;
            z-index: 10000 !important;
            pointer-events: none !important;
            box-shadow: 0 1px 2px rgba(0,0,0,0.05) !important;
            backdrop-filter: blur(2px) !important;
          }
          
          .page-number-indicator {
            position: fixed !important;
            bottom: 15px !important;
            right: 15px !important;
            background: rgba(240, 240, 240, 0.4) !important;
            color: #666666 !important;
            font-size: 11px !important;
            font-weight: normal !important;
            padding: 3px 6px !important;
            border-radius: 3px !important;
            z-index: 10000 !important;
            pointer-events: none !important;
            box-shadow: 0 1px 2px rgba(0,0,0,0.05) !important;
            backdrop-filter: blur(2px) !important;
            opacity: 0.75 !important;
          }
          
          @media print {
            .page-break-indicator, .page-break-label, .page-number-indicator {
              display: none !important;
            }
          }
        `;
        
        // Add a subtle page count indicator
        const pageCountIndicator = iframeDocument.createElement('div');
        pageCountIndicator.className = 'page-number-indicator';
        pageCountIndicator.textContent = `${numberOfPages} Pages`;
        iframeDocument.body.appendChild(pageCountIndicator);
        
        // Create page break indicators for each page boundary
        for (let i = 1; i < numberOfPages; i++) {
          // Create the page break indicator
          const pageBreakIndicator = iframeDocument.createElement('div');
          pageBreakIndicator.className = 'page-break-indicator';
          pageBreakIndicator.style.top = `${PAGE_HEIGHT_PX * i}px`;
          
          // Add page label
          const pageLabel = iframeDocument.createElement('div');
          pageLabel.className = 'page-break-label';
          pageLabel.textContent = `Page ${i+1}`;
          pageBreakIndicator.appendChild(pageLabel);
          
          // Append to the resume container
          resumeContainer.appendChild(pageBreakIndicator);
        }
        
        // Set flag for multi-page
        resumeContainer.setAttribute('data-multi-page', 'true');
        resumeContainer.setAttribute('data-pages', numberOfPages);
        
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error("Error updating page break indicators:", err);
      return false;
    }
  }

  // Modified handlePreviewHTML function with direct PDF generation for iOS
function handleDOWNLOADPDF() {
    try {
        setLoading(true);
        setError("Preparing PDF download...");

        // Get the editor instance
        const editor = grapesEditorRef.current;
        if (!editor) {
            throw new Error("Editor not initialized");
        }

        // Get the iframe from the editor
        const iframe = editor.Canvas.getFrameEl();
        if (!iframe) {
            throw new Error("Cannot find editor frame");
        }

        // Access the iframe document
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        if (!iframeDocument) {
            throw new Error("Cannot access iframe document");
        }

        // Get the resume container
        const resumeContainer =
            iframeDocument.querySelector('div[style*="max-width: 8.5in"]') ||
            iframeDocument.querySelector('.resume-container') ||
            iframeDocument.body;

        // Create simple HTML with just the resume content
        const html = `
        <!DOCTYPE html>
        <html>
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Resume</title>
            <style>
                /* Simple clean styles */
                body {
                    margin: 0;
                    padding: 0;
                    background-color: white;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
                }
                
                .resume-container {
                    width: 100%;
                    max-width: 8.5in;
                    margin: 0 auto;
                    padding: 0;
                    background-color: white;
                }
                
                .controls {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba(255, 255, 255, 0.95);
                    padding: 15px;
                    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
                    display: flex;
                    justify-content: space-between;
                    z-index: 1000;
                }
                
                .btn {
                    padding: 12px 20px;
                    border-radius: 8px;
                    border: none;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                }
                
                .print-btn {
                    background-color: #007AFF;
                    color: white;
                    flex: 1;
                    margin-right: 10px;
                }
                
                .close-btn {
                    background-color: #FF3B30;
                    color: white;
                    flex: 1;
                }
                
                .ios-note {
                    text-align: center;
                    background-color: #FFF7ED;
                    color: #C05621;
                    padding: 10px;
                    border-radius: 8px;
                    margin-bottom: 15px;
                    font-weight: bold;
                    font-size: 14px;
                }
                
                /* Print settings */
                @media print {
                    .controls, .ios-note {
                        display: none !important;
                    }
                    
                    @page {
                        margin: 0;
                        size: letter;
                    }
                    
                    body {
                        margin: 0;
                        padding: 0;
                    }
                }
            </style>
        </head>
        <body>
            <div class="ios-note">
                IMPORTANT: After tapping "Print", select Options and turn OFF Headers and Footers
            </div>
            
            <div class="resume-container">
                ${resumeContainer.innerHTML}
            </div>
            
            <div class="controls">
                <button class="btn print-btn" id="print-btn">Print / Save PDF</button>
                <button class="btn close-btn" id="close-btn">Close</button>
            </div>
            
            <script>
                // Close button handler
                document.getElementById('close-btn').addEventListener('click', function() {
                    window.close();
                });
                
                // Print button handler
                document.getElementById('print-btn').addEventListener('click', function() {
                    // Hide controls
                    document.querySelector('.controls').style.display = 'none';
                    document.querySelector('.ios-note').style.display = 'none';
                    
                    // Wait a moment for UI update
                    setTimeout(function() {
                        window.print();
                        
                        // Show controls again after printing
                        setTimeout(function() {
                            document.querySelector('.controls').style.display = 'flex';
                            document.querySelector('.ios-note').style.display = 'block';
                        }, 1000);
                    }, 300);
                });
            </script>
        </body>
        </html>
        `;

        // Open preview in a new window
        const previewWindow = window.open("", "_blank");
        if (!previewWindow) {
            throw new Error("Unable to open preview window. Please check popup blocker settings.");
        }

        // Write content to the new window
        previewWindow.document.write(html);
        previewWindow.document.close();

        setLoading(false);
        setError("PDF preview ready. Tap 'Print / Save PDF' to download.");
        setTimeout(() => setError(""), 5000);
    } catch (err) {
        console.error("PDF preview error:", err);
        setError(`PDF preview failed: ${err.message}`);
        setLoading(false);
    }
}


  // Function to fetch critique from OpenAI
  async function fetchCritiqueFromOpenAI(jobRequirements, resumeText) {
    if (!jobRequirements || !resumeText || !OPENAI_API_KEY) {
      throw new Error("Missing required data for critique");
    }
    
    try {
      const openAIResponse = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are an AI career expert critiquing resumes for job applications."
            },
            {
              role: "user",
              content: `
Critique the following resume for the job requirements below:
**Job Requirements:** ${jobRequirements}
**Resume:** ${resumeText}
Provide a critique as a structured JSON array where each score is an integer between 0 and 100:
[
  {"factor": "Relevance", "score": <0..100>, "reason": "<brief reason>"},
  {"factor": "Clarity", "score": <0..100>, "reason": "<brief reason>"},
  {"factor": "Grammar", "score": <0..100>, "reason": "<brief reason>"},
  {"factor": "ATS Optimization", "score": <0..100>, "reason": "<brief reason>"},
  {"factor": "Professionalism", "score": <0..100>, "reason": "<brief reason>"}
]
Only return the JSON array, nothing else.
`
            }
          ],
          temperature: 0.1, // low temperature for consistent results
          max_tokens: 500
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_API_KEY}`
          }
        }
      );
      
      let rawCritiqueData = openAIResponse.data.choices[0].message.content;
      const jsonMatch = rawCritiqueData.match(/\[.*\]/s);
      
      if (!jsonMatch) {
        throw new Error("Could not extract JSON data.");
      }
      
      let cleanJsonData = jsonMatch[0]
        .replace(/,\s*]/g, "]") // Remove trailing commas before the closing bracket
        .trim();
      
      return JSON.parse(cleanJsonData);
    } catch (error) {
      console.error("Error fetching critique from OpenAI:", error);
      throw error;
    }
  }


function handlePreviewHTML() {
    try {
        setLoading(true);
        setError("Preparing PDF download...");

        // 1. Get the editor instance
        const editor = grapesEditorRef.current;
        if (!editor) {
            throw new Error("Editor not initialized");
        }

        if (editor) {
            // Clear any current selection
            editor.select(null);
        }
        
        // 2. Get the iframe from the editor
        const iframe = editor.Canvas.getFrameEl();
        if (!iframe) {
            throw new Error("Cannot find editor frame");
        }

        // 3. Access the iframe document
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        if (!iframeDocument) {
            throw new Error("Cannot access iframe document");
        }

        // 4. Get the resume container
        const resumeContainer =
            iframeDocument.querySelector('div[style*="max-width: 8.5in"]') ||
            iframeDocument.querySelector('.resume-container') ||
            iframeDocument.body;

        // 5. Extract all styles from the iframe document
        let allStyles = "";
        const styleElements = iframeDocument.querySelectorAll("style");
        for (const style of styleElements) {
            allStyles += style.innerHTML;
        }

        // 6. Use string concatenation for HTML to avoid template literal issues with @rules
        let html = '<!DOCTYPE html>\n<html>\n<head>\n';
        html += '  <meta charset="UTF-8">\n';
        html += '  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n';
        html += '  <meta name="apple-mobile-web-app-capable" content="yes">\n';
        html += '  <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent">\n';
        html += '  <title>Resume PDF</title>\n';
        html += '  <style>\n';
        
        // Base styles
        html += '    html, body {\n';
        html += '      margin: 0 !important;\n';
        html += '      padding: 0 !important;\n';
        html += '      background-color: white !important;\n';  // Changed from #f5f5f5 to white
        html += '      font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;\n';
        html += '      -webkit-print-color-adjust: exact;\n';
        html += '      print-color-adjust: exact;\n';
        html += '      color-adjust: exact;\n';
        html += '    }\n';
        
        // Resume container styles
        html += '    .resume-wrapper {\n';
        html += '      width: 8.5in;\n';
        html += '      max-width: 100%;\n';
        html += '      margin: 20px auto;\n';
        html += '      background-color: white;\n';
        html += '      position: relative;\n';
        html += '      box-sizing: border-box;\n';
        html += '    }\n';
        
        // UI controls
        html += '    .toolbar {\n';
        html += '      position: fixed;\n';
        html += '      bottom: 0;\n';
        html += '      left: 0;\n';
        html += '      right: 0;\n';
        html += '      background-color: rgba(255, 255, 255, 0.95);\n';
        html += '      backdrop-filter: blur(10px);\n';
        html += '      padding: 15px;\n';
        html += '      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);\n';
        html += '      z-index: 1000;\n';
        html += '      display: flex;\n';
        html += '      flex-direction: column;\n';
        html += '      gap: 8px;\n';
        html += '      border-top: 1px solid rgba(0, 0, 0, 0.1);\n';
        html += '    }\n';
        
        html += '    .instruction-text {\n';
        html += '      font-size: 14px;\n';
        html += '      margin-bottom: 10px;\n';
        html += '      color: #333;\n';
        html += '      text-align: center;\n';
        html += '    }\n';
        
        html += '    .ios-instruction {\n';
        html += '      font-weight: bold;\n';
        html += '      color: #FF3B30;\n';
        html += '      font-size: 14px;\n';
        html += '      margin: 10px 0;\n';
        html += '      padding: 8px;\n';
        html += '      border: 1px solid #FFCCCC;\n';
        html += '      border-radius: 8px;\n';
        html += '      background-color: #FFF5F5;\n';
        html += '      text-align: center;\n';
        html += '      display: none;\n';
        html += '    }\n';
        
        html += '    .button-container {\n';
        html += '      display: flex;\n';
        html += '      gap: 10px;\n';
        html += '    }\n';
        
        html += '    .action-btn {\n';
        html += '      flex: 1;\n';
        html += '      border: none;\n';
        html += '      padding: 14px 0;\n';
        html += '      border-radius: 10px;\n';
        html += '      cursor: pointer;\n';
        html += '      font-weight: bold;\n';
        html += '      font-size: 16px;\n';
        html += '      text-align: center;\n';
        html += '    }\n';
        
        html += '    .print-btn {\n';
        html += '      background-color: #007AFF;\n';
        html += '      color: white;\n';
        html += '    }\n';
        
        html += '    .close-btn {\n';
        html += '      background-color: #8E8E93;\n';
        html += '      color: white;\n';
        html += '    }\n';
        
        // Critical print styles using standard CSS (avoiding @page syntax for Babel)
        html += '    @media print {\n';
        html += '      html, body {\n';
        html += '        width: 8.5in !important;\n';
        html += '        height: 11in !important;\n';
        html += '        margin: 0 !important;\n';
        html += '        padding: 0 !important;\n';
        html += '        background-color: white !important;\n';  // Ensure white background in print mode
        html += '        -webkit-print-color-adjust: exact !important;\n';
        html += '        print-color-adjust: exact !important;\n';
        html += '        color-adjust: exact !important;\n';
        html += '      }\n';
        
        html += '      .resume-wrapper {\n';
        html += '        position: absolute !important;\n';
        html += '        top: 0 !important;\n';
        html += '        left: 0 !important;\n';
        html += '        width: 100% !important;\n';
        html += '        height: auto !important;\n';  // Changed from 100% to auto to allow multiple pages
        html += '        margin: 0 !important;\n';
        html += '        padding: 0 !important;\n';
        html += '        box-shadow: none !important;\n';
        html += '        border: none !important;\n';
        html += '        background-color: white !important;\n';  // Ensure white background for wrapper
        html += '      }\n';
        
        html += '      .toolbar, #instructions-overlay {\n';
        html += '        display: none !important;\n';
        html += '      }\n';
        
        html += '      hr, div[style*="border-bottom"], div[style*="border-top"] {\n';
        html += '        border-color: black !important;\n';
        html += '        border-width: 1px !important;\n';
        html += '        border-style: solid !important;\n';
        html += '        -webkit-print-color-adjust: exact !important;\n';
        html += '        print-color-adjust: exact !important;\n';
        html += '      }\n';
        
        html += '      h2, h3, [data-section] {\n';
        html += '        border-bottom: 1px solid black !important;\n';
        html += '        padding-bottom: 5px !important;\n';
        html += '        margin-bottom: 10px !important;\n';
        html += '        -webkit-print-color-adjust: exact !important;\n';
        html += '        print-color-adjust: exact !important;\n';
        html += '      }\n';
        
        // Fixed the two-column layout styling for printing
        html += '      .resume-wrapper > div[style*="display: flex"] {\n';
        html += '        min-height: auto !important;\n';  // Changed from 11in to auto
        html += '        height: auto !important;\n';
        html += '        background-color: white !important;\n';  // Ensure white background
        html += '      }\n';
        
        html += '      .resume-wrapper > div[style*="display: flex"] > div {\n';
        html += '        min-height: auto !important;\n';  // Changed from inherit to auto
        html += '        height: auto !important;\n';
        html += '        background-color: inherit !important;\n';  // Ensure background color is inherited properly
        html += '      }\n';
        
        // Fixed the sidebar background to properly apply to all pages
        html += '      .resume-wrapper > div[style*="display: flex"] > div:first-child {\n';
        html += '        background-color: #f0f0f0 !important;\n';
        html += '        -webkit-print-color-adjust: exact !important;\n';
        html += '        print-color-adjust: exact !important;\n';
        html += '        color-adjust: exact !important;\n';
        html += '        min-height: 100% !important;\n';  // Make sure sidebar covers full height
        html += '      }\n';
        
        // Ensure each page gets proper styling 
        html += '      @page {\n';
        html += '        margin: 0;\n';
        html += '        size: letter;\n';
        html += '      }\n';
        
        // Specific fix for the background issue on multiple pages
        html += '      .resume-wrapper > div[style*="display: flex"]::after {\n';
        html += '        content: "";\n';
        html += '        display: block;\n';
        html += '        clear: both;\n';
        html += '      }\n';
        
        html += '    }\n';
        
        // Original styles from editor
        html += allStyles;
        
        // Ensure print colors are preserved
        html += '    * {\n';
        html += '      -webkit-print-color-adjust: exact !important;\n';
        html += '      print-color-adjust: exact !important;\n';
        html += '      color-adjust: exact !important;\n';
        html += '    }\n';
        
        // Additional mobile adjustments
        html += '    @media screen and (min-width: 601px) {\n';
        html += '      .toolbar {\n';
        html += '        position: fixed;\n';
        html += '        top: 10px;\n';
        html += '        bottom: auto;\n';
        html += '        right: 10px;\n';
        html += '        left: auto;\n';
        html += '        width: auto;\n';
        html += '        max-width: 350px;\n';
        html += '        border-radius: 10px;\n';
        html += '      }\n';
        html += '    }\n';
        
        // Instructions overlay
        html += '    #instructions-overlay {\n';
        html += '      position: fixed;\n';
        html += '      top: 0;\n';
        html += '      left: 0;\n';
        html += '      right: 0;\n';
        html += '      bottom: 0;\n';
        html += '      background-color: rgba(0,0,0,0.8);\n';
        html += '      z-index: 2000;\n';
        html += '      display: none;\n';
        html += '      flex-direction: column;\n';
        html += '      align-items: center;\n';
        html += '      justify-content: center;\n';
        html += '      color: white;\n';
        html += '      padding: 20px;\n';
        html += '      text-align: center;\n';
        html += '    }\n';
        
        html += '    .instructions-content {\n';
        html += '      max-width: 360px;\n';
        html += '      background-color: rgba(255,255,255,0.9);\n';
        html += '      padding: 20px;\n';
        html += '      border-radius: 16px;\n';
        html += '      color: #333;\n';
        html += '      box-shadow: 0 4px 20px rgba(0,0,0,0.5);\n';
        html += '    }\n';
        
        html += '    .instructions-title {\n';
        html += '      font-size: 18px;\n';
        html += '      font-weight: bold;\n';
        html += '      margin-bottom: 15px;\n';
        html += '    }\n';
        
        html += '    .instructions-steps {\n';
        html += '      margin-bottom: 20px;\n';
        html += '      text-align: left;\n';
        html += '    }\n';
        
        html += '    .instruction-step {\n';
        html += '      margin-bottom: 15px;\n';
        html += '      position: relative;\n';
        html += '      padding-left: 30px;\n';
        html += '      display: flex;\n';
        html += '      align-items: center;\n';
        html += '    }\n';
        
        html += '    .step-number {\n';
        html += '      position: absolute;\n';
        html += '      left: 0;\n';
        html += '      top: 0;\n';
        html += '      width: 22px;\n';
        html += '      height: 22px;\n';
        html += '      background-color: #007AFF;\n';
        html += '      color: white;\n';
        html += '      border-radius: 50%;\n';
        html += '      display: flex;\n';
        html += '      align-items: center;\n';
        html += '      justify-content: center;\n';
        html += '      font-weight: bold;\n';
        html += '    }\n';
        
        html += '    .highlight-text {\n';
        html += '      color: #FF3B30;\n';
        html += '      font-weight: bold;\n';
        html += '    }\n';
        
        html += '    .instructions-image {\n';
        html += '      width: 100%;\n';
        html += '      max-width: 300px;\n';
        html += '      margin: 10px auto;\n';
        html += '      border-radius: 8px;\n';
        html += '      border: 2px solid #007AFF;\n';
        html += '    }\n';
        
        html += '    .instructions-btn {\n';
        html += '      background-color: #007AFF;\n';
        html += '      color: white;\n';
        html += '      border: none;\n';
        html += '      padding: 12px 20px;\n';
        html += '      border-radius: 10px;\n';
        html += '      font-weight: bold;\n';
        html += '      font-size: 16px;\n';
        html += '      margin-top: 15px;\n';
        html += '      cursor: pointer;\n';
        html += '    }\n';
        
        html += '    .share-icon {\n';
        html += '      width: 20px;\n';
        html += '      height: 20px;\n';
        html += '      margin-left: 5px;\n';
        html += '      vertical-align: middle;\n';
        html += '      display: inline-block;\n';
        html += '    }\n';
        
        html += '  </style>\n';
        html += '</head>\n';
        html += '<body>\n';
        html += '  <div class="resume-wrapper">\n';
        html += resumeContainer.innerHTML;
        html += '  </div>\n';
        
        html += '  <div class="toolbar">\n';
        html += '    <div class="instruction-text">Choose an option to download your resume</div>\n';
        html += '    <div class="ios-instruction" id="ios-instruction">For iPhone: Use <span style="color: #FF3B30; font-weight: bold;">Print</span> button and tap Options → Turn OFF Headers & Footers</div>\n';
        html += '    <div class="button-container">\n';
        html += '      <button class="action-btn print-btn" id="print-btn">Print / Save PDF</button>\n';
        html += '      <button class="action-btn close-btn" id="close-btn">Close</button>\n';
        html += '    </div>\n';
        html += '  </div>\n';
        
        // Detailed Instructions Overlay
        html += '  <div id="instructions-overlay">\n';
        html += '    <div class="instructions-content">\n';
        html += '      <div class="instructions-title" id="instructions-title">How to Save as PDF on iPhone</div>\n';
        
        // iOS Instructions
        html += '      <div id="ios-steps">\n';
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">1</div>\n';
        html += '          <div>\n';
        html += '            Tap <span class="highlight-text">Share Button</span> under the URL\n';
        html += '            <svg class="share-icon" viewBox="0 0 24 24" width="20" height="20">\n';
        html += '              <path fill="#007AFF" d="M12,5.5L7,10.5H11V17.5H13V10.5H17L12,5.5M5,20.5H19A2,2 0 0,0 21,18.5V14.5H19V18.5H5V6.5H19V10.5H21V6.5A2,2 0 0,0 19,4.5H5A2,2 0 0,0 3,6.5V18.5A2,2 0 0,0 5,20.5Z" />\n';
        html += '            </svg>\n';
        html += '          </div>\n';
        html += '        </div>\n';
        
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">2</div>\n';
        html += '          <div>Tap <span class="highlight-text">Options</span> at the top</div>\n';
        html += '        </div>\n';
        
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">3</div>\n';
        html += '          <div>Select <span class="highlight-text">PDF</span></div>\n';
        html += '        </div>\n';
        
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">4</div>\n';
        html += '          <div>Press <span class="highlight-text">Done</span> at the top right</div>\n';
        html += '        </div>\n';
        
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">5</div>\n';
        html += '          <div>Choose <span class="highlight-text">Save to Files</span></div>\n';
        html += '        </div>\n';
        html += '      </div>\n';
        
        // Android Instructions
        html += '      <div id="android-steps" style="display: none;">\n';
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">1</div>\n';
        html += '          <div>Tap <span class="highlight-text">Print</span> button below</div>\n';
        html += '        </div>\n';
        
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">2</div>\n';
        html += '          <div>Select <span class="highlight-text">Save as PDF</span> from the printer options</div>\n';
        html += '        </div>\n';
        
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">3</div>\n';
        html += '          <div>Tap <span class="highlight-text">Save</span> icon (may appear as a download icon)</div>\n';
        html += '        </div>\n';
        
        html += '        <div class="instruction-step">\n';
        html += '          <div class="step-number">4</div>\n';
        html += '          <div>Choose a location to save your PDF file</div>\n';
        html += '        </div>\n';
        html += '      </div>\n';
        
        html += '      <button class="instructions-btn" id="instructions-close-btn">Got it</button>\n';
        html += '    </div>\n';
        html += '  </div>\n';
        
        // Simple script without ES6 template literals or complex logging
        html += '  <script>\n';
        html += '    // Detect devices\n';
        html += '    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;\n';
        html += '    var isAndroid = /Android/.test(navigator.userAgent);\n';
        html += '    var isMobile = isIOS || isAndroid || /Mobile|Windows Phone|Lumia|Blackberry/i.test(navigator.userAgent);\n';
        
        html += '    // Get elements\n';
        html += '    var iosInstruction = document.getElementById("ios-instruction");\n';
        html += '    var printBtn = document.getElementById("print-btn");\n';
        html += '    var closeBtn = document.getElementById("close-btn");\n';
        html += '    var instructionsOverlay = document.getElementById("instructions-overlay");\n';
        html += '    var instructionsCloseBtn = document.getElementById("instructions-close-btn");\n';
        html += '    var instructionsTitle = document.getElementById("instructions-title");\n';
        html += '    var iosSteps = document.getElementById("ios-steps");\n';
        html += '    var androidSteps = document.getElementById("android-steps");\n';
        
        html += '    // Set up UI based on device\n';
        html += '    if (isIOS) {\n';
        html += '      iosInstruction.style.display = "block";\n';
        html += '      instructionsTitle.textContent = "How to Save as PDF on iPhone";\n';
        html += '      iosSteps.style.display = "block";\n';
        html += '      androidSteps.style.display = "none";\n';
        html += '    } else if (isAndroid) {\n';
        html += '      instructionsTitle.textContent = "How to Save as PDF on Android";\n';
        html += '      iosSteps.style.display = "none";\n';
        html += '      androidSteps.style.display = "block";\n';
        html += '    }\n';
        
        html += '    // Automatically show instructions for mobile devices\n';
        html += '    if (isMobile) {\n';
        html += '      instructionsOverlay.style.display = "flex";\n';
        html += '    }\n';
        
        html += '    // Close button handler\n';
        html += '    closeBtn.addEventListener("click", function() {\n';
        html += '      window.close();\n';
        html += '    });\n';
        
        html += '    // Instructions overlay controls\n';
        html += '    instructionsCloseBtn.addEventListener("click", function() {\n';
        html += '      instructionsOverlay.style.display = "none";\n';
        html += '    });\n';
        
        html += '    // Print button handler\n';
        html += '    printBtn.addEventListener("click", function() {\n';
        html += '      if (isMobile) {\n';
        html += '        // For mobile devices, show instructions first\n';
        html += '        instructionsOverlay.style.display = "flex";\n';
        html += '        \n';
        html += '        // Set proceed button to handle print\n';
        html += '        instructionsCloseBtn.textContent = "Continue to Print";\n';
        html += '        \n';
        html += '        // When they click continue, proceed to print\n';
        html += '        instructionsCloseBtn.onclick = function() {\n';
        html += '          instructionsOverlay.style.display = "none";\n';
        html += '          instructionsCloseBtn.textContent = "Got it";\n';
        html += '          handlePrint();\n';
        html += '          \n';
        html += '          // Reset button behavior\n';
        html += '          instructionsCloseBtn.onclick = function() {\n';
        html += '            instructionsOverlay.style.display = "none";\n';
        html += '          };\n';
        html += '        };\n';
        html += '      } else {\n';
        html += '        // For desktop, just print directly\n';
        html += '        handlePrint();\n';
        html += '      }\n';
        html += '    });\n';
        
        html += '    // Print handling function\n';
        html += '    function handlePrint() {\n';
        html += '      // Hide toolbar before printing\n';
        html += '      document.querySelector(".toolbar").style.display = "none";\n';
        
        html += '      // Create a style element\n';
        html += '      var printStyle = document.createElement("style");\n';
        html += '      printStyle.textContent = \n';
        html += '        "html, body { " +\n';
        html += '        "  margin: 0mm !important; " +\n';
        html += '        "  padding: 0mm !important; " +\n';
        html += '        "  background-color: white !important; " +\n';  // Ensure white background
        html += '        "} " +\n';
        
        // Fixed styling for two-column layout in print view
        html += '        "@page { margin: 0; size: letter; } " +\n';
        
        html += '        ".resume-wrapper { " +\n';
        html += '        "  background-color: white !important; " +\n';
        html += '        "} " +\n';
        
        html += '        ".resume-wrapper > div[style*=\'display: flex\'] { " +\n';
        html += '        "  min-height: auto !important; " +\n';
        html += '        "  height: auto !important; " +\n';
        html += '        "  background-color: white !important; " +\n';
        html += '        "} " +\n';
        
        // This is the key fix for the sidebar background
        html += '        ".resume-wrapper > div[style*=\'display: flex\'] > div:first-child { " +\n';
        html += '        "  background-color: #f0f0f0 !important; " +\n';
        html += '        "  position: relative !important; " +\n';  // Add position relative
        html += '        "  -webkit-print-color-adjust: exact !important; " +\n';
        html += '        "  print-color-adjust: exact !important; " +\n';
        html += '        "  color-adjust: exact !important; " +\n';
        html += '        "  box-decoration-break: clone !important; " +\n';  // Add this to maintain styling across page breaks
        html += '        "  min-height: 100% !important; " +\n';
        html += '        "} " +\n';
        
        // Fix for sidebar content positioning
        html += '        ".resume-wrapper > div[style*=\'display: flex\'] > div:first-child > * { " +\n';
        html += '        "  position: relative !important; " +\n';
        html += '        "  z-index: 1 !important; " +\n';
        html += '        "} " +\n';
        
        html += '      document.head.appendChild(printStyle);\n';
        
        html += '      // iOS reminder\n';
        html += '      if (isIOS) {\n';
        html += '        alert("IMPORTANT: After clicking Print, tap Options and make sure Headers & Footers are OFF");\n';
        html += '      } else if (isAndroid) {\n';
        html += '        alert("IMPORTANT: Select Save as PDF from the printer options");\n';
        html += '      }\n';
        
        html += '      // Print after a short delay\n';
        html += '      setTimeout(function() {\n';
        html += '        window.print();\n';
        
        html += '        // Restore UI after printing\n';
        html += '        setTimeout(function() {\n';
        html += '          document.querySelector(".toolbar").style.display = "flex";\n';
        html += '          document.head.removeChild(printStyle);\n';
        html += '        }, 1000);\n';
        html += '      }, 200);\n';
        html += '    }\n';
        
        html += '    // Auto-trigger print after delay for desktop only\n';
        html += '    if (!isMobile) {\n';
        html += '      setTimeout(function() {\n';
        html += '        handlePrint();\n';
        html += '      }, 1000);\n';
        html += '    }\n';
        html += '  </script>\n';
        html += '</body>\n';
        html += '</html>';

        // 7. Open preview in a persistent window
        const previewWindow = window.open("", "_blank");

        if (!previewWindow) {
            throw new Error("Unable to open preview window. Please check popup blocker settings.");
        }

        previewWindow.document.open();
        previewWindow.document.write(html);
        previewWindow.document.close();

        setLoading(false);
        setError("PDF download ready.");
        setTimeout(() => setError(""), 5000);
    } catch (err) {
        console.error("PDF download preparation error:", err);
        setError(`PDF download failed: ${err.message}`);
        setLoading(false);
    }
}



async function checkSubsctiptionAndHandlePreviewHTML() {
  try {

     // First save the optimized resume to the database
     await saveOptimizedResume();
    
    // First check subscription status
    checkSubscriptionStatus().then(hasSubscription => {
        if (hasSubscription) {
            // User has subscription, proceed with PDF download
            handlePreviewHTML();
        } else {
            //Log attempt to download
            axios.post('/log-activity', {  sessionId: auth.sessionId,event_type:"Button Press",current_page:'DocumentBuilderPage' ,object_type:'Page',specific_object:'Attempted to download resume and got sales pitch' }, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
            // User doesn't have subscription, show sales pitch modal
            setShowSalesPitchModal(true);
        }
    });
} catch (err) {
    console.error("Error checking subscription status:", err);
    setError(`Error: ${err.message}`);
}
}

// Updated function to check subscription status using user role type
async function checkSubscriptionStatus() {
  try {
    const response = await axios.post('/get-user-role-type', {
      sessionId: auth.sessionId
    });
    
    //console.log("User role type check response:", response.data);
    
    // Check if user has premium role (where "BASIC PLAN" means premium in this system)
    const isPremium = response.data.userRoleType === "BASIC PLAN";
    
    return isPremium;
  } catch (err) {
    console.error("Error checking user role type:", err);
    return false; // Default to no subscription on error
  }
}


// Improved RTF export function with more consistent formatting
function handleDownloadRTF() {
    try {
      setLoading(true);
      setError("Generating RTF document...");
      
      // 1. Get the editor instance
      const editor = grapesEditorRef.current;
      if (!editor) {
        throw new Error("Editor not initialized");
      }
      
      // 2. Get the iframe from the editor
      const iframe = editor.Canvas.getFrameEl();
      if (!iframe) {
        throw new Error("Cannot find editor frame");
      }
      
      // 3. Access the iframe document
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      if (!iframeDocument) {
        throw new Error("Cannot access iframe document");
      }
      
      // 4. Get the main resume container within the iframe
      let resumeContainer = iframeDocument.querySelector('div[style*="max-width: 8.5in"]');
      if (!resumeContainer) {
        resumeContainer = iframeDocument.querySelector('.resume-container');
      }
      if (!resumeContainer) {
        resumeContainer = iframeDocument.body;
      }
      
      if (!resumeContainer) {
        throw new Error("Cannot find resume container");
      }
      
      // 5. Define RTF header
      const rtfHeader = `{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033
  {\\fonttbl
  {\\f0\\fswiss\\fcharset0 Arial;}
  {\\f1\\froman\\fcharset0 Times New Roman;}
  {\\f2\\fswiss\\fcharset0 Helvetica;}
  }
  {\\colortbl;
  \\red0\\green0\\blue0;
  \\red0\\green0\\blue255;
  \\red33\\green33\\blue33;
  }
  \\viewkind4\\uc1\\pard\\sa200\\sl276\\slmult1\\qc
  `;
      
      const rtfFooter = "\\par }";
      
      // 6. Extract text and structure from the HTML
      const extractTextFromNode = (node, styleInfo = {}) => {
        if (!node) return "";
        
        let result = "";
        
        if (node.nodeType === Node.TEXT_NODE) {
          // Handle text node
          let text = node.textContent
            .replace(/\\/g, '\\\\')
            .replace(/\{/g, '\\{')
            .replace(/\}/g, '\\}')
            .replace(/\r?\n/g, '\\par ');
          
          // Apply styling
          if (styleInfo.bold) text = `{\\b ${text}}`;
          if (styleInfo.italic) text = `{\\i ${text}}`;
          if (styleInfo.underline) text = `{\\ul ${text}}`;
          
          return text;
        }
        
        if (node.nodeType === Node.ELEMENT_NODE) {
          const tagName = node.tagName.toLowerCase();
          const computedStyle = window.getComputedStyle(node);
          
          // Create style info for this element
          const newStyleInfo = {
            ...styleInfo,
            bold: styleInfo.bold || 
                  tagName === 'b' || 
                  tagName === 'strong' || 
                  computedStyle.fontWeight === 'bold' || 
                  parseInt(computedStyle.fontWeight) >= 700,
            italic: styleInfo.italic || 
                   tagName === 'i' || 
                   tagName === 'em' || 
                   computedStyle.fontStyle === 'italic',
            underline: styleInfo.underline || 
                      tagName === 'u' || 
                      computedStyle.textDecoration.includes('underline')
          };
          
          // Handle block elements
          if (tagName === 'h1') {
            result += "\\pard\\sa200\\sl276\\slmult1\\qc\\b\\fs36 ";
          } else if (tagName === 'h2') {
            result += "\\pard\\sa200\\sl276\\slmult1\\b\\fs32 ";
          } else if (tagName === 'h3') {
            result += "\\pard\\sa200\\sl276\\slmult1\\b\\fs28 ";
          } else if (tagName === 'h4') {
            result += "\\pard\\sa200\\sl276\\slmult1\\b\\fs24 ";
          } else if (tagName === 'p') {
            result += "\\pard\\sa200\\sl276\\slmult1 ";
          } else if (tagName === 'div') {
            // Only add paragraph mark if it contains direct text
            const hasDirectText = Array.from(node.childNodes).some(child => 
              child.nodeType === Node.TEXT_NODE && child.textContent.trim().length > 0
            );
            if (hasDirectText) {
              result += "\\pard\\sa200\\sl276\\slmult1 ";
            }
          } else if (tagName === 'ul' || tagName === 'ol') {
            // List containers need no special handling
          } else if (tagName === 'li') {
            result += "\\pard\\sa200\\sl276\\slmult1\\fi-360\\li720\\bullet\\tab ";
          }
          
          // Process children
          for (const child of node.childNodes) {
            result += extractTextFromNode(child, newStyleInfo);
          }
          
          // Add paragraph break after certain elements
          if (['h1', 'h2', 'h3', 'h4', 'p', 'li'].includes(tagName)) {
            result += "\\par ";
          }
        }
        
        return result;
      };
      
      // 7. Generate the RTF body by extracting text and formatting
      const rtfBody = extractTextFromNode(resumeContainer);
      
      // 8. Combine header, body, and footer
      const rtfContent = rtfHeader + rtfBody + rtfFooter;
      
      // 9. Create a Blob and trigger download
      const blob = new Blob([rtfContent], { type: "application/rtf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "resume.rtf";
      link.click();
      URL.revokeObjectURL(url);
      
      setLoading(false);
      setError("");
      
    } catch (err) {
      console.error("RTF export error:", err);
      setError(`RTF export failed: ${err.message}`);
      setLoading(false);
    }
  }


// Mobile-friendly HTML export that works across all devices
function handleExportHTML() {
    try {
      setLoading(true);
      setError("Generating HTML document...");
      
      // 1. Get the editor instance
      const editor = grapesEditorRef.current;
      if (!editor) {
        throw new Error("Editor not initialized");
      }
      
      // 2. Get the iframe from the editor
      const iframe = editor.Canvas.getFrameEl();
      if (!iframe) {
        throw new Error("Cannot find editor frame");
      }
      
      // 3. Access the iframe document
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      if (!iframeDocument) {
        throw new Error("Cannot access iframe document");
      }
      
      // 4. Get the main resume container within the iframe
      let resumeContainer = iframeDocument.querySelector('div[style*="max-width: 8.5in"]');
      if (!resumeContainer) {
        resumeContainer = iframeDocument.querySelector('.resume-container');
      }
      if (!resumeContainer) {
        resumeContainer = iframeDocument.body;
      }
      
      if (!resumeContainer) {
        throw new Error("Cannot find resume container");
      }
      
      // 5. Get all styles from the document
      let allStyles = '';
      try {
        // Extract styles from style tags
        const styleTags = Array.from(iframeDocument.querySelectorAll('style'))
          .map(style => style.textContent)
          .join('\n');
        
        // Extract styles from stylesheets
        const styleSheets = Array.from(iframeDocument.styleSheets || []);
        const cssRules = styleSheets.map(sheet => {
          try {
            return Array.from(sheet.cssRules || [])
              .map(rule => rule.cssText)
              .join('\n');
          } catch (e) {
            // Cross-origin stylesheet error - ignore
            return '';
          }
        }).join('\n');
        
        allStyles = styleTags + '\n' + cssRules;
      } catch (e) {
        console.warn("Error extracting styles:", e);
      }
      
      // 6. Create a comprehensive HTML document with responsive meta tags
      const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Resume</title>
          <style>
            /* Reset styles */
            body, html {
              margin: 0;
              padding: 0;
              font-family: 'Helvetica Neue', Arial, sans-serif;
              background-color: white;
              color: black;
            }
            
            /* Main container styling */
            .resume-container {
              width: 8.5in;
              margin: 0 auto;
              padding: 0;
              background-color: white;
              box-shadow: none;
              border: none;
            }
            
            /* Remove all borders */
            * {
              border: none !important;
              box-shadow: none !important;
            }
            
            /* Print optimization */
            @media print {
              body {
                width: 100%;
                height: 100%;
              }
              
              .resume-container {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                box-shadow: none !important;
                border: none !important;
              }
            }
            
            /* Responsive adjustments */
            @media screen and (max-width: 8.5in) {
              .resume-container {
                width: 100%;
                padding: 0 10px;
                box-sizing: border-box;
              }
            }
            
            /* Original styles from document */
            ${allStyles}
          </style>
        </head>
        <body>
          <div class="resume-container">
            ${resumeContainer.innerHTML}
          </div>
        </body>
        </html>
      `;
      
      // 7. Create a downloadable blob
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      
      // 8. Trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'resume.html';
      link.click();
      
      // 9. Clean up
      URL.revokeObjectURL(url);
      
      setLoading(false);
      setError("");
      
    } catch (err) {
      console.error("HTML export error:", err);
      setError(`HTML export failed: ${err.message}`);
      setLoading(false);
    }
  }

  // Toggle templates panel on mobile
  const toggleTemplates = () => {
    setShowTemplates(!showTemplates);
  };
// Define the OptimizationProgressModal component function
const OptimizationProgressModal = () => {
    if (!optimizing) return null;
    
    return (
      <div className="parse-progress-modal">
        <div className="parse-progress-content">
          <h3>AI Resume Optimization</h3>
          
          {optimizationProgress < 100 ? (
            <>
              <p>Our AI is tailoring your resume for {jobTitle} at {companyName}...</p>
              <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${optimizationProgress}%` }} />
              </div>
              <p className="optimization-status">
                {optimizationProgress < 30 && "Analyzing job requirements..."}
                {optimizationProgress >= 30 && optimizationProgress < 60 && "Enhancing relevant skills..."}
                {optimizationProgress >= 60 && optimizationProgress < 90 && "Optimizing for ATS systems..."}
                {optimizationProgress >= 90 && "Finalizing your professional resume..."}
              </p>
            </>
          ) : (
            <p>Optimization complete! Your resume is now tailored for this position.</p>
          )}
        </div>
      </div>
    );
  };

  // Function to optimize resume with OpenAI
async function optimizeResumeWithAI(resumeText, jobRequirements) {
    if (!resumeText || !jobRequirements) {
      console.warn("Missing resume text or job requirements for optimization");
      return resumeText;
    }
    
    try {
      // console.log("Optimizing resume with OpenAI...");
      
      const openAIResponse = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are an expert resume writer who specializes in optimizing resumes for specific job postings."
            },
            {
              role: "user",
              content: `
  Enhance this resume to better match the job requirements. Focus on these factors:
  1. Relevance - Highlight experiences and skills that match the job.
  2. Clarity - Ensure the resume clearly communicates qualifications.
  3. Grammar - Fix any grammatical errors.
  4. ATS Optimization - Include keywords from the job posting.
  5. Professionalism - Maintain a professional, industry-appropriate tone.
  
  **Job Requirements:** ${jobRequirements}
  
  **Original Resume:**
  ${resumeText}
  
  Return ONLY the optimized resume text, with no explanations or additional comments.
  `
            }
          ],
          temperature: 0.2,
          max_tokens: 3000
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_API_KEY}`
          }
        }
      );
      
      const optimizedResume = openAIResponse.data.choices[0].message.content.trim();
      return optimizedResume;
      
    } catch (err) {
      console.error("Error optimizing resume with AI:", err);
      return resumeText; // Return original resume as fallback
    }
  }

// Create template gallery rendering function
const renderTemplateGallery = () => {
    if (!isMobileView || showTemplates) {
      return (
        <div className={`template-gallery ${isMobileView ? 'template-gallery-mobile' : ''}`}>
          {!isMobileView && <h3 className="template-gallery-header">Choose Template Style</h3>}
          
          <div className="template-buttons">
            {TEMPLATES.map((tpl) => (
              <button
                key={tpl.id}
                className={`template-button ${selectedTemplate?.id === tpl.id ? 'selected' : ''}`}
                onClick={() => handleSelectTemplate(tpl.id)}
              >
                <span className="template-name">{tpl.templateName}</span>
                {selectedTemplate?.id === tpl.id && <span className="check-icon">✓</span>}
              </button>
            ))}
          </div>
          
          {isMobileView && (
            <button 
              className="close-templates-btn" 
              onClick={() => setShowTemplates(false)}
            >
              Close
            </button>
          )}
        </div>
      );
    }
    return null;
  };

  // Function to extract the current resume text from the editor
function extractCurrentResumeText() {
  try {
    // Get the editor instance
    const editor = grapesEditorRef.current;
    if (!editor) {
      console.error("Editor not initialized");
      return "";
    }
    
    // Get the HTML content
    const currentHtml = editor.getHtml();
    
    // Create a temporary div to extract text
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = currentHtml;
    
    // Remove any script tags for security
    const scripts = tempDiv.querySelectorAll('script');
    scripts.forEach(script => script.remove());
    
    // Get the text content
    const resumeText = tempDiv.textContent || tempDiv.innerText || "";
    
    return resumeText;
  } catch (err) {
    console.error("Error extracting resume text:", err);
    return "";
  }
}

// Function to save the optimized resume to the database
async function saveOptimizedResume() {
  try {
    // Extract the current resume text from the editor
    const optimizedResumeText = extractCurrentResumeText();

    setGlobalOptimizedResumeText(optimizedResumeText)
    
    if (!optimizedResumeText || optimizedResumeText.length < 100) {
      console.warn("Extracted resume text is too short or empty");
      return false;
    }


    const response = await axios.post('/update-job-application', {
      sessionId: auth.sessionId,
      applicationId: applicationId,
      optimizedResumeText: optimizedResumeText
    });
    
    if (response.data && response.data.success) {
      console.log("Successfully saved optimized resume to database");
      return true;
    } else {
      console.warn("Failed to save optimized resume:", response.data);
      return false;
    }
  } catch (err) {
    console.error("Error saving optimized resume:", err);
    return false;
  }
}


  // Add this debug function to help identify the container issue
function debugContainers() {
    try {
      const editor = grapesEditorRef.current;
      if (!editor) return;
      
      const iframe = editor.Canvas.getFrameEl();
      if (!iframe) return;
      
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      if (!doc) return;
      
      //console.log("=== DOCUMENT STRUCTURE DEBUG ===");
      //console.log("Body children:", doc.body.children.length);
      
      // Find potential container candidates
      const allDivs = doc.querySelectorAll('div');
      //console.log("Total divs:", allDivs.length);
      
      // Log largest divs
      const divsBySize = Array.from(allDivs).map(div => ({
        element: div,
        height: div.offsetHeight,
        class: div.className,
        id: div.id,
        style: div.getAttribute('style')
      })).sort((a, b) => b.height - a.height);
      
      //console.log("Largest divs:", divsBySize.slice(0, 5));
      
      // Look for specific attributes
      const divsWithMaxWidth = doc.querySelectorAll('div[style*="max-width"]');
      //console.log("Divs with max-width:", divsWithMaxWidth.length);
      
      const resumeContainers = doc.querySelectorAll('.resume-container');
      //console.log("Divs with resume-container class:", resumeContainers.length);
      
      // Create visual debug overlays
      divsBySize.slice(0, 3).forEach((info, i) => {
        const overlay = doc.createElement('div');
        overlay.style.position = 'absolute';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.border = `5px solid ${['red', 'blue', 'green'][i]}`;
        overlay.style.zIndex = 9000 + i;
        overlay.style.pointerEvents = 'none';
        info.element.style.position = 'relative';
        info.element.appendChild(overlay);
        
        const label = doc.createElement('div');
        label.style.position = 'absolute';
        label.style.top = '0';
        label.style.left = '0';
        label.style.background = ['red', 'blue', 'green'][i];
        label.style.color = 'white';
        label.style.padding = '5px';
        label.style.fontSize = '12px';
        label.style.zIndex = 9010 + i;
        label.textContent = `Container #${i+1}: ${info.height}px h, class=${info.class||'none'}`;
        overlay.appendChild(label);
      });
      
      //console.log("Debug overlays added to largest containers");
    } catch (err) {
      console.error("Error in debug function:", err);
    }
  }

// Main render
return (
    <div>
    <div className={`document-builder-page ${isMobileView ? 'mobile-view' : 'desktop-view'}`}>
      {/* Optimization Progress Modal */}
      <OptimizationProgressModal />
      
      {/* Actions header (for both mobile and desktop) */}
      <div className={`action-header ${isMobileView ? 'mobile-header' : 'desktop-header'}`}>
  <h2 className="resume-builder-heading">
    <i className="fas fa-file-alt resume-builder-icon"></i>
    <span>Resume </span>
    <span className="ai-text">Builder</span>
  </h2>

  {/* {
          <div style={{ marginBottom: "1rem" }}>
            <div style={{ 
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center",
              marginBottom: "0.5rem",
              position: "relative"
            }}>
              <div style={{ 
                textAlign: "center", 
                marginRight: "40px", 
                position: "relative"
              }}>
                <div style={{ fontSize: "0.9rem", color: "#777" }}>Before</div>
                <div style={{ 
                  fontSize: "1.8rem", 
                  fontWeight: "bold",
                  color: scoreColor(averageScore)
                }}>
                  {averageScore}%
                </div>
              </div>
              
              <div style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#4CAF50",
                fontSize: "1.5rem",
                fontWeight: "bold"
              }}>
                →
              </div>
              
              <div style={{ textAlign: "center", marginLeft: "10px" }}>
                <div style={{ fontSize: "0.9rem", color: "#777" }}>After</div>
                <div style={{ 
                  fontSize: "1.8rem", 
                  fontWeight: "bold",
                  color: scoreColor(optimizedScore)
                }}>
                  {optimizedScore}%
                </div>
              </div>
            </div>
            
      
          </div>
         } */}
  
  <div className="action-buttons">
    {isMobileView && (
      <button 
        className="action-btn template-selector-btn" 
        onClick={toggleTemplates}
      >
        <i className="fas fa-palette"></i>
        Choose Template
      </button>
    )}
    
    <button 
      className="action-btn reset-btn" 
      onClick={handleResetToOriginal}
      title="Reset to original optimized resume"
    >
      <i className="fas fa-undo"></i>
      Reset Resume
    </button>
    
    {/* Cover Letter Link */}
    <Link
      // This 'to' prop defines the destination URL
      to={`/generate-letter/${applicationId}/cover-letter`}
      // Pass all required data using the state prop
      state={{
        missingElements,
        applicationId,
        auth,
        payRange,
        averageScore,
        optimizedScore,
        scoreImprovement
      }}
      className="action-btn generate-letter-btn" // Style as a button
      title="Generate Cover Letter based on this resume and job"
    >
      <i className="fas fa-envelope"></i> Gen Cover Letter
    </Link>

    {/* Hiring Manager Letter Button (using Link) */}
    <Link
      // This 'to' prop defines the destination URL
      to={`/generate-letter/${applicationId}/hiring-manager-letter`}
      // Pass all required data using the state prop
      state={{
        missingElements,
        applicationId,
        auth,
        payRange,
        averageScore,
        optimizedScore,
        scoreImprovement
      }}
      className="action-btn generate-hm-letter-btn" // Style as a button
      title="Generate Hiring Manager outreach letter"
    >
      <i className="fas fa-user-tie"></i> Gen HM Letter
    </Link>


     <button 
      className={`action-btn ${showButtonAttention ? 'attention' : ''}`}
      id="downloadPdfButton"
      onClick={checkSubsctiptionAndHandlePreviewHTML}
    >
      <i className="fas fa-download"></i>
      Download PDF
    </button>
    
    {TextScaleControls()}
  </div>
  
  {error && <div className="error-message">{error}</div>}
</div>  
       {/* Sales Pitch Modal */}
      {showSalesPitchModal && (
          <Modal 
              onClose={() => setShowSalesPitchModal(false)}
              missingElements={missingElements}
              applicationId={applicationId}
              auth={auth}
              payRange={payRange}
              averageScore={averageScore}
              optimizedScore={optimizedScore}
              scoreImprovement={scoreImprovement}
          />
      )}
      {/* Main layout container */}
      <div className="layout-container">
        {/* LEFT COLUMN: Template Gallery */}
        {renderTemplateGallery()}
        
        {/* MAIN: Editor (expanded for both mobile and desktop) */}
        <div 
          ref={editorContainerRef}
          className={`editor-container ${isMobileView ? 'editor-container-mobile' : 'editor-container-desktop'}`}
        />
        
        {/* Hidden preview for final HTML reference */}
        <div 
          ref={previewRef}
          id="final-resume"
          style={{ display: 'none' }}
        />
      </div>
      
      {/* Loading indicator */}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Processing your resume...</p>
        </div>
      )}


    </div>
    <ProgressBar
            currentStep={4}
            totalSteps={4}
            stepLabels={["Upload Resume", "Job Details", "Critique", "Optimize / Finalize"]}
            stepPaths={[
              "/UploadResume",
              "/JobDetails",
              `/critique/${auth.applicationId}`,
              "/optimize"
            ]}
          />
               <FeaturedEmployers />
               <SocialProof />
    </div>
  );
}