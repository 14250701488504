import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from './api/axios';
import CareerGeniusLogo from './assets/CareerGeniusLogo.png';
import FolderIcon from './assets/FolderIcon.png';
import './UploadResume.css';

const ColdTrafficLandingPage = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  
  const handleStartScan = () => {
    navigate('/LoginForm');
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    // Always redirect to login/signup instead of actually uploading
    navigate('/LoginForm');
  };

  useEffect(() => {
    // Log page view
    try {
      const sessionId = localStorage.getItem('sessionId') || 'anonymous-session';
      
      axios.post('/log-activity', {
        sessionId: sessionId,
        event_type: "Page Landing",
        current_page: 'COLD LANDING PAGE',
        object_type: 'Page',
        specific_object: 'Landed on COLD LANDING PAGE page'
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).catch(err => console.log('Logging error (non-critical):', err));
    } catch (error) {
      console.log('Error logging activity:', error);
    }
  }, []);

  return (
    <div className="home-container">
      <header className="header">
        <img src={CareerGeniusLogo} alt="CareerGenius Logo" className="logo" />
      </header>
      
      <div className="form-container">
        <h2>
          Stop Getting <span className="highlight">Rejected By<br />ATS Bots</span> Before Humans<br />See Your Resume
        </h2>
        
        <p>
          CareerGenius instantly analyzes your resume against job descriptions, reveals hidden keyword gaps, and helps you land 3X more interviews.
        </p>
        
        {/* Upload Box - Added from UploadResume component */}
        <div className="upload-container" style={{ margin: '2rem 0' }}>
          <input
            type="file"
            id="fileUpload"
            className="file-input"
            onChange={handleFileChange}
            accept=".pdf, .docx"
          />
          <label htmlFor="fileUpload" className="upload-box">
            <img src={FolderIcon} alt="Upload Folder" className="upload-icon animated-hover" />
            <span className="upload-text">
              Upload Your Resume to Scan
            </span>
          </label>
        </div>

        {selectedFile && (
          <p className="file-name">{selectedFile.name}</p>
        )}
        
        <button 
          className="btn upload-btn" 
          onClick={handleUpload} 
          style={{ padding: '14px 28px', fontSize: '1.1em', marginTop: selectedFile ? '1rem' : '0' }}
        >
          {selectedFile ? 'Scan My Resume Now' : 'Create Free Account & Start Scanning'}
        </button>
        
        <p style={{ fontWeight: 'bold', marginTop: '1.5rem', fontSize: '1.1em' }}>
          <span className="highlight">Unlimited free scans</span> with your one-time account creation
        </p>
        
        <div className="upload-note" style={{ fontStyle: 'italic' }}>
          No credit card required. Instant results.
        </div>
        
        <div className="upload-container" style={{ marginTop: '2.5rem', padding: '1.5rem', backgroundColor: 'rgba(240, 240, 240, 0.3)' }}>
          <h3 className="highlight" style={{ marginBottom: '1rem' }}>How CareerGenius Works</h3>
          
          <ul style={{ textAlign: 'left', margin: '1.2rem 0', lineHeight: '1.6' }}>
            <li><strong>Step 1:</strong> Create your free account (takes 30 seconds)</li>
            <li><strong>Step 2:</strong> Upload your current resume</li>
            <li><strong>Step 3:</strong> Paste any job description you're targeting</li>
            <li><strong>Step 4:</strong> Get your detailed scan with keyword matches, ATS compatibility score, and formatting suggestions</li>
            <li><strong>Step 5:</strong> Optimize instantly with our free editor (premium download options available with free trial)</li>
          </ul>
        </div>
        
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2.5rem', padding: '1rem', border: '1px solid #eaeaea', borderRadius: '8px' }}>
          <div className="upload-note" style={{ fontStyle: 'italic', fontSize: '1.1em' }}>
            "After months of silence, CareerGenius showed me exactly what keywords I was missing. I optimized my resume in minutes and landed 4 interviews the next week. Now employed at my dream company." 
            <br />
            <strong style={{ marginTop: '0.5rem', display: 'block' }}>– Tina L., Marketing Professional</strong>
          </div>
        </div>
        
        <div style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ fontWeight: 'bold', color: '#333' }}>
            Join 1,000+ job seekers who stopped getting ignored
          </p>
          
          <button className="btn upload-btn" style={{ marginTop: '1rem', padding: '16px 32px', fontSize: '1.2em' }} onClick={handleStartScan}>
            Get Your Free Resume Scan
          </button>
          
          <div className="upload-note" style={{ marginTop: '0.5rem' }}>
            Unlimited free scans. Premium downloads available with free trial.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColdTrafficLandingPage;