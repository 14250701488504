import React, { useState, useContext, useEffect } from "react";
import axios from "./api/axios";
import AuthContext from "./context/AuthProvider";
import CareerGeniusLogo from "./assets/CareerGeniusLogo.png";
import "./JobDetails.css";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./components/ProgressBar";
import FeaturedEmployers from "./FeaturedEmployers";
import SocialProof from "./SocialProof";

// Add the missing imports:

const ChatGPTAPIKey = process.env.REACT_APP_GPT_API_KEY;

function JobDetails() {
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  // Basic states
  const [jobTitle, setJobTitle] = useState("");
  const [jobLink, setJobLink] = useState("");
  const [scrapedData, setScrapedData] = useState(null); // Data from link
  const [manualJobDescription, setManualJobDescription] = useState(""); // Manual fallback

  // AI extraction states
  const [aiExtractedData, setAIExtractedData] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [payRange, setPayRange] = useState("");

  // UI states
  const [loading, setLoading] = useState(false);     // For link-scraping
  const [aiLoading, setAiLoading] = useState(false);   // For AI extraction & pay range guess
  const [errorMessage, setErrorMessage] = useState("");
  const [useManual, setUseManual] = useState(false);
  const [showExtractedInfo, setShowExtractedInfo] = useState(false);

  // Auto-proceed flag to ensure we only navigate once.
  const [autoProceedTriggered, setAutoProceedTriggered] = useState(false);

  useEffect(() => {
    setJobTitle(auth.username);
  }, [auth.username]);

  const [localCredits, setLocalCredits] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => { 
    axios.post('/log-activity', {  sessionId: auth.sessionId,event_type:"Page Landing",current_page:'JobDetials',object_type:'Page',specific_object:'Landed on JobDetials page'}, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  }, []);

  useEffect(() => {
    if (
      auth.user_role_type === "BASIC PLAN" ||
      auth.user_role_type === "ADVANTAGE PLAN" ||
      auth.user_role_type === "ELITE PLAN"
    ) {
      setIsSubscribed(true);
    }
  }, [auth.user_role_type]);



  useEffect(() => {
    if (!auth.username) {
      //console.log("User not logged in. Redirecting to login...");
      navigate("/LoginForm");
      return;
    }
  }, []);

  function toggleInputMethod() {
    setUseManual((prev) => !prev);
    // Clear all existing data so the user has a fresh start
    setScrapedData(null);
    setAIExtractedData("");
    setCompanyName("");
    setLocationName("");
    setPayRange("");
    setManualJobDescription("");
    setJobLink("");
    setErrorMessage("");
    setJobTitle("");
  }

  function isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  function cleanText(text) {
    if (!text) return "";
    return text.replace(/[\t\n\r]/g, " ").trim();
  }

  async function handleScrape() {
    if (!jobLink.trim() || !isValidURL(jobLink.trim())) {
      setErrorMessage("Please enter a valid link or switch to manual entry.");
      return;
    }

    setErrorMessage("");
    setLoading(true);

    try {
      const response = await axios.post("/scrape-job", { jobLink });
      if (response.data && response.data.success) {
        const title = cleanText(response.data.jobTitle);
        const description = cleanText(response.data.fullText || response.data.rawDescriptionHTML);

        const scraped = {
          title: title || "",
          description: description || "",
        };
        setScrapedData(scraped);

        if (scraped.title !== "" && scraped.description.length >= 200) {
          setShowExtractedInfo(true);
        }

        if (!scraped.description || scraped.description.length < 200) {
          setErrorMessage("No valid job details found. Switching to manual entry...");
          setUseManual(true);
          setLoading(false);
          return;
        }

        if (scraped.description) {
          await extractJobDetailsAI(scraped.description);
        }
      } else {
        setErrorMessage("Scraping failed. Switching to manual entry...");
        setUseManual(true);
      }
    } catch (error) {
      console.error("🔥 Error scraping job:", error);
      setErrorMessage("Could not scrape the job. Switching to manual entry...");
      setUseManual(true);
    } finally {
      setLoading(false);
    }
  }

  async function extractJobDetailsAI(jobText) {
    if (!jobText.trim()) {
      setErrorMessage("No job description provided for AI processing.");
      return;
    }

    try {
      setAiLoading(true);
      setErrorMessage("");
      setAIExtractedData("");
      setCompanyName("");
      setLocationName("");
      setPayRange("");

      const aiResponse = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                "You are a job recruitment assistant that extracts job details. " +
                "Return the details in exactly the following format with each field on its own line:\n" +
                "Job Title: [job title or 'Not provided' if missing]\n" +
                "Company Name: [company name or 'Not provided' if missing]\n" +
                "Job Requirements: [job requirements or 'Not provided' if missing]\n" +
                "Qualifications: [qualifications or 'Not provided' if missing]\n" +
                "Skills Needed: [skills needed or 'Not provided' if missing]\n" +
                "Experience Needed: [experience needed or 'Not provided' if missing]\n" +
                "Candidate Expectations: [candidate expectations or 'Not provided' if missing]\n" +
                "Location: [location or 'Not provided' if missing]\n" +
                "Do not include any extra text."
            },
            {
              role: "user",
              content: `Extract the above details from the following job description:\n${jobText}`
            }
          ],
          temperature: 0.3,
          max_tokens: 500
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${ChatGPTAPIKey}`
          }
        }
      );

      if (aiResponse.data?.choices?.length) {
        const aiContent = aiResponse.data.choices[0].message.content.trim();
        setAIExtractedData(aiContent);
        //console.log("Raw AI content:", aiContent);
        //console.log("AI content length:", aiContent.length);

        const titleMatch = aiContent.match(/^(Job Title|Title):\s*(.+)$/im);
        //console.log("Title match result:", titleMatch);

        if (titleMatch?.[2] && titleMatch[2].trim().toLowerCase() !== "not provided") {
          setScrapedData((prev) => ({
            ...prev,
            title: titleMatch[2].trim()
          }));
          setShowExtractedInfo(true);
        } else {
          setErrorMessage("No valid job details from AI. Switching to manual entry...");
          setUseManual(true);
        }

        const companyMatch = aiContent.match(/Company Name:\s*(.+)$/im);
        if (companyMatch?.[1]) {
          setCompanyName(companyMatch[1].trim());
        }
        const locationMatch = aiContent.match(/Location:\s*(.+)$/im);
        if (locationMatch?.[1]) {
          setLocationName(locationMatch[1].trim());
        }

        if (!useManual && aiContent.length < 200) {
          console.warn("AI response too short.");
          setErrorMessage("AI response too short. Switching to manual entry...");
          setUseManual(true);
        }
      } else {
        setErrorMessage("No valid AI response. Switching to manual entry...");
        setUseManual(true);
      }
    } catch (error) {
      console.error("🔥 Error extracting job details via ChatGPT:", error);
      setErrorMessage("Failed to extract details from AI. Switching to manual entry...");
      setUseManual(true);
    } finally {
      setAiLoading(false);
    }
  }

  async function guessPayRangeViaAI({ jobTitle, location, jobDescription }) {
    if (!jobDescription.trim()) {
      setErrorMessage("No job description provided for pay range guess.");
      return "";
    }

    try {
      setAiLoading(true);
      setErrorMessage("");
      setPayRange("");

      const userContent = `
Given the job title: "${jobTitle}"
${location ? `in "${location}"` : ""}
and the following job description:
"${jobDescription}"

Please guess a likely salary range (min - max).
Return your answer in a short JSON format, e.g.:
{
  "minSalary": "50000",
  "maxSalary": "70000",
  "currency": "USD"
}
      `;

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are a helpful AI that estimates salary ranges based on job info."
            },
            {
              role: "user",
              content: userContent
            }
          ],
          temperature: 0.3,
          max_tokens: 300
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ChatGPTAPIKey}`
          }
        }
      );

      let guessedRange = "";
      if (response.data?.choices?.length) {
        const rawContent = response.data.choices[0].message.content.trim();
        const jsonMatch = rawContent.match(/\{[\s\S]*\}/);
        if (jsonMatch) {
          const payRangeData = JSON.parse(jsonMatch[0]);
          if (payRangeData.minSalary && payRangeData.maxSalary) {
            guessedRange = `${payRangeData.minSalary} - ${payRangeData.maxSalary} ${payRangeData.currency || "USD"}`;
            setPayRange(guessedRange);
          } else {
            guessedRange = "No clear salary data found.";
            setPayRange(guessedRange);
          }
        } else {
          guessedRange = "Could not parse pay range JSON.";
          setPayRange(guessedRange);
        }
      } else {
        guessedRange = "No valid AI response for pay range.";
        setPayRange(guessedRange);
      }
      return guessedRange;
    } catch (err) {
      console.error("🔥 Error fetching pay range from AI:", err);
      setErrorMessage("Failed to guess pay range from AI.");
      return "";
    } finally {
      setAiLoading(false);
    }
  }

  async function handleProceed(e) {
    e.preventDefault();
    if (!aiExtractedData.trim()) {
      alert("Please extract job details before proceeding.");
      return;
    }

    let computedPayRange = payRange;
    if (!computedPayRange) {
      computedPayRange = await guessPayRangeViaAI({
        jobTitle: jobTitle || (scrapedData && scrapedData.title) || "Unknown",
        location: locationName,
        jobDescription: (scrapedData && scrapedData.description) || manualJobDescription || ""
      });
    }

    try {
      const response = await axios.post("/submit-application", {
        username: auth.username,
        job_title: (scrapedData && scrapedData.title) || jobTitle || "N/A",
        job_link: jobLink || "Manually Entered",
        job_requirements: aiExtractedData,
        original_resume_text: "",
        company_name: companyName || "N/A",
        pay_range: computedPayRange || "N/A",
        location_name: locationName || "N/A"
      });
      //console.log("📜 Scraped Data:", scrapedData);

      if (response.data.success) {
        const newAppId = response.data.id;
        setAuth({ ...auth, applicationId: newAppId });
        navigate(`/critique/${response.data.id}`);
      } else {
        alert("Error: Application submission failed.");
      }
    } catch (error) {
      console.error("🔥 Error submitting job application:", error);
      alert("Error: Could not submit application.");
    }
  }

  useEffect(() => {
    if (!autoProceedTriggered && showExtractedInfo && scrapedData?.title && aiExtractedData.trim()) {
      setAutoProceedTriggered(true);
      handleProceed({ preventDefault: () => {} });
    }
  }, [showExtractedInfo, scrapedData, aiExtractedData, autoProceedTriggered]);

  const scrapeFailed = errorMessage.includes("scrape") || errorMessage.includes("Scraping failed");

  return (
    <div className="job-details-container">
      <header className="header">
        <img src={CareerGeniusLogo} alt="CareerGenius Logo" className="logo" />
      </header>

      <div className="job-details-form">
        <div className="job-header">
          <h2 className="job-title">
            <i className="fa fa-briefcase job-icon"></i>
            <span>Application </span>
            <span className="highlight">Details</span>
          </h2>
        </div>
        <p className="form-description">
          {useManual
            ? "Paste your job description below."
            : "Enter the job link below to automatically fetch details."}
        </p>

        {!useManual && (
          <>
   {/*          <div className="input-group">
              <label className="input-label">Job Title (Optional):</label>
              <input
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                className="input-field"
                placeholder="e.g., Software Engineer"
              />
            </div> */}

            <div className="input-group">
              <label className="input-label">Job Link:</label>
              <input
                type="text"
                value={jobLink}
                onChange={(e) => setJobLink(e.target.value)}
                className="input-field"
                placeholder="https://company.com/job"
              />
            </div>

            <button className="submit-btn" onClick={handleScrape} disabled={loading || aiLoading}>
              {loading ? <FaSpinner className="spinner" /> : "Fetch Job Details 🔍"}
            </button>
          </>
        )}

        <p
          className="toggle-input-link"
          onClick={toggleInputMethod}
          style={{ cursor: "pointer", color: "#007bff", textDecoration: "underline" }}
        >
          {useManual ? "Use Link Scraping Instead" : "Enter Description Manually"}
        </p>

        {useManual && (
          <div className="input-group">
            <label className="input-label">Job Description:</label>
            <textarea
              value={manualJobDescription}
              onChange={(e) => setManualJobDescription(e.target.value)}
              className="input-field"
              placeholder="Paste the full job description here..."
              rows={6}
            />
            <button className="submit-btn" onClick={() => extractJobDetailsAI(manualJobDescription)} disabled={aiLoading || !manualJobDescription.trim()}>
              {aiLoading ? (
                <>
                  <FaSpinner className="spinner" /> Extracting...
                </>
              ) : (
                "Extract Job Details with AI 🤖"
              )}
            </button>
          </div>
        )}

        {errorMessage && (
          <div className="error-message">
            <p>❌ {errorMessage}</p>
          </div>
        )}

        <ProgressBar
          currentStep={2}
          totalSteps={4}
          stepLabels={[
            "Upload Resume",
            "Job Details",
            "Critique",
            "Optimize / Finalize"
          ]}
          stepPaths={[
            "/UploadResume",
            "/JobDetails",
            `/critique/${auth.applicationId}`,
            "/optimize"
          ]}
        />
      </div>

      <FeaturedEmployers />
      <SocialProof inModal={false} />
    </div>
  );
}

export default JobDetails;
