import React, { useEffect, useState } from "react";
import "./SalesPitchPage.css"; // Add styling if needed
import FeaturedEmployers from "../../FeaturedEmployers";
import SocialProof from "../../SocialProof";
import axios from "../../api/axios";
import StripeSubscriptionButton from '../StripeSubscriptionButton.jsx';
import AuthContext from "../../context/AuthProvider";

function scoreColor(score) {
  if (score >= 90) return "#00aa66"; // Dark green for excellent scores
  if (score >= 80) return "#00cc88"; // Green for good scores
  if (score >= 60) return "#ffbb33"; // Amber for average scores
  return "#ff4444"; // Red for low scores
}

function SalesPitchPage({ onClose, missingElements, applicationId, auth, payRange,averageScore, optimizedScore,scoreImprovement}) {
  const [salary, setSalary] = useState(0);
  const [showButtonAttention, setShowButtonAttention] = useState(true);
  const [isStripeLoading, setIsStripeLoading] = useState(false);

  useEffect(() => {
    if (auth?.sessionId) {
      axios.post('/log-activity', {
        sessionId: auth.sessionId,
        event_type: "Page Landing",
        current_page: 'SalesPitchPage',
        object_type: 'Page',
        specific_object: 'Sales Pitch modal popped up'
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).catch(err => console.error("🔥 Log Activity Error:", err));
    } else {
      console.warn("⚠️ sessionId missing: auth context not ready yet.", auth);
    }
  }, [applicationId, auth.sessionId]);


/*   useEffect(() => {
    let attentionInterval;
    const initialDelay = setTimeout(() => {
      setShowButtonAttention(true);
      attentionInterval = setInterval(() => {
        setShowButtonAttention(prev => !prev);
      }, 4000); // Toggle every 4 seconds
    }, 2000); // Start after 2 seconds
  
    return () => {
      clearTimeout(initialDelay);
      if (attentionInterval) {
        clearInterval(attentionInterval);
      }
    };
  }, []); */
  

  function extractMaxPay(payRange) {
    // Ensure payRange is a string
    if (typeof payRange !== "string") {
      console.error("Expected payRange to be a string, but got:", payRange);
      // Optionally, you can either throw an error or return a default value.
      return 0; // or throw new Error('Invalid payRange type');
    }
    
    // Extract all number groups from the string.
    const numbers = payRange.match(/\d+/g);
    
    // Ensure we have at least two numbers.
    if (!numbers || numbers.length < 2) {
      throw new Error("Invalid payRange format");
    }
    
    // Convert extracted strings to integers and return the larger number.
    const [num1, num2] = numbers.map(num => parseInt(num, 10));
    return Math.max(num1, num2);
  }

  return (
    <div className="modal-overlay show" style={{ backgroundColor: "rgba(0, 0, 0, 0.35)" }}>
      <div
        className="modal-content"
        style={{
          borderRadius: "10px",
          padding: "24px",
          backgroundColor: "#ffffff",
          maxWidth: "460px",
          margin: "0 auto",
          boxShadow: "0 8px 20px rgba(0,0,0,0.15)",
          textAlign: "center",
          position: "relative",
        }}
      >
        <button
          className="modal-close"
          onClick={onClose}
          aria-label="Close Modal"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "28px",
            height: "28px",
            background: "#f1f1f1",
            border: "none",
            borderRadius: "50%",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333",
            position: "absolute",
            top: "12px",
            right: "16px",
            cursor: "pointer",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            zIndex: 10,
          }}
        >
          ✕
        </button>
        
        <h2 style={{ color: "#2C3E50", fontWeight: 600, marginBottom: "0.8rem" }}>
          {scoreImprovement > 0 
            ? "Your Resume Is Getting Better!" 
            : "Download Your Optimized Resume"}
        </h2>
        
       
        {scoreImprovement > 0 ? (
          <div style={{ marginBottom: "1rem" }}>
            
            <p style={{ fontSize: "1rem", color: "#444" }}>
              <span style={{ fontWeight: "bold", color: "#4CAF50" }}>
                +{optimizedScore - averageScore}% improvement!
              </span> {" "}
              Upgrade now to download your optimized resume.
            </p>
          </div>
        ) : (
          <p style={{ fontSize: "1rem", marginBottom: "1rem", color: "#444" }}>
            Your resume's overall score is{" "}
            <strong style={{ color: scoreColor(optimizedScore || averageScore) }}>
              {optimizedScore || averageScore}%
            </strong>
            {" "} (Industry Avg: <strong>88%</strong>).
          </p>
        )}

        {/* Button with updated glow effect implementation */}
        {isStripeLoading && (
          <p style={{ 
            textAlign: 'center', 
            marginBottom: '10px', 
            fontSize: '0.95rem', 
            color: '#555', 
            fontStyle: 'italic' 
          }}>
            Please be patient as the payment form loads. This may take a few moments...
          </p>
        )}
        <div 
          style={{ 
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            margin: '20px 0'
          }}>
          <div className={showButtonAttention ? 'attention-button' : ''} 
            style={{ 
              borderRadius: '8px',
              overflow: 'hidden',
              width: '100%'
            //  maxWidth: '280px' /* Adjust based on your button's actual size */
            }}>
            <StripeSubscriptionButton setIsStripeLoading={setIsStripeLoading} />
          </div>
        </div>
        
        {/* Information Box */}
        <div style={{
          background: "#f8f9fa",
          padding: "15px",
          borderRadius: "8px",
          marginBottom: "1.2rem",
          border: "1px solid #e9ecef"
        }}>
          <p style={{ fontSize: "1rem", marginBottom: "0.7rem", color: "#333", fontWeight: "500" }}>
            Every week of delay can cost you <strong style={{ color: "#2E8B57" }}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(payRange / 52)}
            </strong> in missed salary!
          </p>
          
          {optimizedScore < 90 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ 
                flexShrink: 0,
                background: "#FF8F00", 
                width: "10px", 
                height: "10px", 
                borderRadius: "50%",
                marginRight: "10px" 
              }}></div>
              <p style={{ fontSize: "0.95rem", margin: 0, color: "#555", textAlign: "left" }}>
                <strong>Potential Impact:</strong> A premium resume could increase interview chances by 62%
              </p>
            </div>
          )}
        </div>

        {/* Action Message */}
        <div style={{
          backgroundColor: optimizedScore >= 90 ? "#e8f5e9" : "#fff8e1",
          padding: "12px",
          borderRadius: "6px",
          marginBottom: "15px",
          borderLeft: `4px solid ${optimizedScore >= 90 ? "#4CAF50" : "#FFC107"}`
        }}>
          <p style={{ 
            margin: 0, 
            fontSize: "0.95rem", 
            color: optimizedScore >= 90 ? "#1B5E20" : "#F57F17",
            fontWeight: "500"
          }}>
            {optimizedScore >= 90 
              ? "Your resume is looking great! Unlock premium features to download and start applying today."
              : "Our premium tools can help you reach the 90+ score range that top employers look for!"}
          </p>
        </div>
        
        {/* Featured Employers */}
        <FeaturedEmployers inModal={true} />
      </div>
    </div>
  );
}

export default SalesPitchPage;