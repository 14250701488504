import React, { useState, useEffect, useContext } from 'react';
import axios from "../api/axios";
import AuthContext from "../context/AuthProvider";
import './StripeSubscriptionButton.css';

const StripeSubscriptionButton = ({ setIsStripeLoading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(7); // Default to 7 days
  const { auth } = useContext(AuthContext);
  
  // Get the critique ID from the URL if it exists
  const location = window.location.pathname;
  const critiqueid = location.includes('/critique/') ? 
    location.split('/critique/')[1] : null;
  
  // Get the full current URL for returning to the same page
  const returnUrl = window.location.href;

  // Check if user has an active subscription and determine trial days
  useEffect(() => {
    const checkSubscriptionDetails = async () => {
      try {
        if (auth.sessionId) {
          // First check current subscription
          const response = await axios.post('/get-subscription', {
            sessionId: auth.sessionId
          });
          
          const subscription = response.data;
          setSubscriptionInfo(subscription);
          setHasSubscription(subscription && subscription.status === 1);
          
          // Check subscription history to determine remaining trial days
          const historyResponse = await axios.post('/check-remaining-trial-days', {
            sessionId: auth.sessionId
          });
          
          if (historyResponse.data && historyResponse.data.remainingDays) {
            // If there are remaining days, use that value
            setTrialDaysRemaining(historyResponse.data.remainingDays);
          } else {
            // Otherwise default to 7 days
            setTrialDaysRemaining(7);
          }
        }
      } catch (err) {
        console.error("Error checking subscription details:", err);
        // Default to 7 days if there's an error
        setTrialDaysRemaining(7);
      }
    };
  
    checkSubscriptionDetails();
  }, [auth.sessionId, auth.username]);

  // Handle subscription button click
  const handleSubscribe = async () => {
    try {
      setIsLoading(true);

      axios.post('/log-activity', {
        sessionId: auth.sessionId,
        event_type: "Button Press",
        current_page: 'StripeSubscriptionButton',
        object_type: 'Page',
        specific_object: 'Attempted to press subscribe button'
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true
      });
      
      // Use the determined trial days from state
      const response = await axios.post('/create-stripe-subscription', {
        sessionId: auth.sessionId,
        plan: 'premium',
        cost: 12.99,
        billingInterval: 'month',
        trialDays: trialDaysRemaining,
        critiqueid: critiqueid,
        returnUrl: returnUrl
      });
      
      // Redirect to Stripe Checkout
      if (response.data.url) {
        // TikTok tracking code - keeping as is
        if (window.ttq) {
          window.ttq.track('AddToCart', {
            contents: [
              {
                content_id: 'resume_optimization_plan',
                content_type: 'product',
                content_name: 'Optimized Resume Subscription'
              }
            ],
            value: 12.99,
            currency: 'USD'
          });

          window.ttq.track('InitiateCheckout', {
            contents: [
              {
                content_id: 'resume_optimization_plan',
                content_type: 'product',
                content_name: 'Optimized Resume Subscription'
              }
            ],
            value: 12.99,
            currency: 'USD'
          });
        }

        window.location.href = response.data.url;
      } else {
        throw new Error("No checkout URL returned");
      }
    } catch (err) {
      console.error("Error creating subscription:", err);
      alert("Failed to start subscription process. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle subscription cancellation
  const handleCancelSubscription = async () => {
    if (!subscriptionInfo || !subscriptionInfo.subscriptionId) return;
    
    try {
      setIsLoading(true);
      
      if (subscriptionInfo.subscriptionId.startsWith('sub_') || subscriptionInfo.subscriptionId.startsWith('cs_')) {
        await axios.post('/cancel-stripe-subscription', {
          sessionId: auth.sessionId,
          subscriptionId: subscriptionInfo.subscriptionId
        });
      } else {
        await axios.post('/cancel-subscription', {
          sessionId: auth.sessionId,
          subId: subscriptionInfo.subscriptionId
        });
      }
      
      const response = await axios.post('/get-subscription', {
        sessionId: auth.sessionId
      });
      
      setSubscriptionInfo(response.data);
      setHasSubscription(response.data && response.data.status === 1 && !response.data.cancel_subscription);
      
      alert("Your subscription has been canceled. You'll have access until the end of your billing period.");
    } catch (err) {
      console.error("Error canceling subscription:", err);
      alert("Failed to cancel subscription. Please try again or contact support.");
    } finally {
      setIsLoading(false);
    }
  };

  // New function to handle uncanceling a subscription
  const handleUncancelSubscription = async () => {
    if (!subscriptionInfo || !subscriptionInfo.subscriptionId) return;
    
    try {
      setIsLoading(true);
      
      // Call server endpoint to uncancel the subscription
      await axios.post('/renew-subscription-stripe', {
        sessionId: auth.sessionId,
        subscriptionId: subscriptionInfo.subscriptionId
      });
      
      // Refresh subscription info
      const response = await axios.post('/get-subscription', {
        sessionId: auth.sessionId
      });
      
      setSubscriptionInfo(response.data);
      setHasSubscription(response.data && response.data.status === 1);
      
      alert("Your subscription has been renewed. You will continue to have premium access.");
    } catch (err) {
      console.error("Error renewing subscription:", err);
      alert("Failed to renew subscription. Please try again or contact support.");
    } finally {
      setIsLoading(false);
    }
  };
  
  // For subscriptions marked for cancellation - now with continue option
  if (hasSubscription && subscriptionInfo && subscriptionInfo.cancel_subscription === 1) {
    return (
      <div className="subscription-container-pro">
        <div className="status-content">
          <span className="status-icon">
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 7H13V13H11V7ZM11 15H13V17H11V15Z" fill="currentColor"/>
            </svg>
          </span>
          <span className="status-text">Premium (Canceling at period end)</span>
        </div>
        <button 
          className="subscription-btn-pro"
          onClick={handleUncancelSubscription}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="btn-loading-pro">
              <div className="spinner-pro"></div>
            </div>
          ) : 'Continue My Subscription'}
        </button>
      </div>
    );
  }
  
  // For active subscriptions
  if (hasSubscription) {
    return (
      <div className="subscription-container-pro">
        <div className="premium-badge-pro">
          <svg className="checkmark-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7L19.59 5.59L9 16.17Z" fill="currentColor"/>
          </svg>
          <span>Premium Active</span>
        </div>
        <button 
          className="cancel-subscription-btn-pro" 
          onClick={handleCancelSubscription}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="btn-loading-pro">
              <div className="spinner-pro"></div>
            </div>
          ) : 'Cancel Subscription'}
        </button>
      </div>
    );
  }

  // For non-subscribers
  return (
    <button 
      className="subscription-btn-pro" 
      onClick={handleSubscribe}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className="btn-loading-pro">
          <div className="spinner-pro"></div>
          <span>Processing...</span>
        </div>
      ) : (
        <>
          <svg className="lock-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 16.1 13.1 17 12 17ZM15.1 8H8.9V6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8Z" fill="currentColor"/>
          </svg>
          <span>
            {`Start ${trialDaysRemaining}-Day Free Trial - Unlock Download`}
          </span>
        </>
      )}
    </button>
  );
};

export default StripeSubscriptionButton;